import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"

import { VehiclesIcon } from "@garantidos/components"

//Components
import { useAlert } from "@mobi/ds"
// Tracking
import analytics from "@mobi/libraries/analytics"
import { useStep } from "@mobi/libraries/step"
import phoneMask from "@mobi/utils/formatters/phone"

// Services
import services from "services"
import { updateToken } from "services/auth"
import { getUrlParamAll, getUUIDFromUrl, resetFocus } from "utils/browser"
// Utils
import {
	getFromSessionStorage,
	saveOnSessionStorage,
	storage
} from "utils/storage"

// Contexts
import { Context } from "contexts/simulation"

const useVerificationCodeGeneration = () => {
	const [smsStatus, setSmsStatus] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const { simulation, setSimulation } = useContext(Context)
	const { next, prev } = useStep()
	const Alert = useAlert()
	const phoneNumber = phoneMask(getFromSessionStorage({ name: "phone" }) || "")

	const isClosedFlow = getFromSessionStorage({ name: "isClosedFlow" }) || false
	const hideBackButton = getUrlParamAll()?.origin === "email"
	const uuid = getUUIDFromUrl()

	const formProps = useForm({
		mode: "onChange",
		defaultValues: {
			notifyMethod: "",
			code: ""
		}
	})

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		resetFocus()

		const getSimulation = async () => {
			const updatedSimulation = await services.verificationCode
				.getSimulation()
				.catch(() => {
					setIsLoading(false)
					return
				})

			if (updatedSimulation?.lead?.phone) {
				saveOnSessionStorage({
					name: "phone",
					value: updatedSimulation?.lead?.phone
				})
			}

			setSimulation({ ...simulation, ...updatedSimulation })
			setIsLoading(false)
		}

		getSimulation()
		sendCode({})
		analytics.track("pageLoad", {})
	}, [])

	const saveLastCodeSent = () => {
		const register = new Date()
		storage.session.setItem("lastCodeSent", register)
	}

	const sendCode = async ({ resend = false }) => {
		setIsLoading(true)

		const simulation = await services.verificationCode
			.updateGeneration(
				{ notifyMethod: "SMS" },
				{ sourceUrl: window?.location?.href }
			)
			.catch(() => {
				setIsLoading(false)
				return
			})

		if (simulation?.data) {
			saveLastCodeSent()
			updateToken(simulation?.data?.auth)
			setIsLoading(false)

			if (resend) {
				setSmsStatus("success")
				formProps.setValue("code", "")
				Alert?.success("código reenviado, verifique seu novo código no SMS", {
					icon: <VehiclesIcon id="icon-outlined-notification" />
				})
			}
		}
	}

	const nextStep = (nextStepName, confirmation) => {
		setSimulation({
			...simulation,
			...confirmation,
			currentFormStep: nextStepName
		})
		next(nextStepName)
	}

	const onSubmit = async (payload) => {
		setIsLoading(true)
		setSmsStatus(false)

		const confirmation = await services.verificationCode
			.updateConfirmation(
				{ ...payload, notifyMethod: "SMS" },
				{ sourceUrl: window?.location?.href },
				uuid
			)
			.catch((err) => {
				formProps.setError("code", {
					type: "invalidCode"
				})
				setSmsStatus("error")
				setIsLoading(false)
				return err
			})

		if (confirmation.data) {
			updateToken(confirmation?.data?.auth)

			const updatedSimulation = await services.verificationCode
				.getSimulation()
				.catch(() => {
					setIsLoading(false)
					return
				})

			analytics.track("verificationCodeConfirmation", { notifyMethod: "SMS" })
			nextStep("simulation", updatedSimulation)
		}
	}

	const resendCode = () => {
		sendCode({ resend: true })
	}

	const onPrev = () => {
		const newSimulationOnPrev = {
			...simulation,
			nextFormStep: simulation.currentFormStep,
			currentFormStep: simulation.previousFormStep
		}
		setSimulation(newSimulationOnPrev)
		prev(simulation?.previousFormStep)
	}

	return {
		onSubmit,
		onPrev,
		resendCode,
		setSmsStatus,
		smsStatus,
		hideBackButton,
		phoneNumber,
		formProps,
		isLoading,
		isClosedFlow
	}
}

export default useVerificationCodeGeneration
