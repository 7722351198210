// Components
import { Text } from "@mobi/ds"
import If from "@mobi/components/If"
import Loader from "components/Loader"
import { PurchasePeriod } from "..//components/PurchasePeriod"
import Sender from "components/Sender"
import Stepper from "components/Stepper"
import Header from "components/Header"

// Hooks
import useVehiclePurchasePeriod from "./useVehiclePurchasePeriod"
import { StepAsideImage } from "../components/StepAsideImage"

// Utils
import isMobile from "@mobi/utils/browser/isMobile"

// Styles
import "./index.scss"

const VehiclePurchasePeriod = () => {
  const { isLoading, onSubmit, formProps, nextFormStep } =
    useVehiclePurchasePeriod()

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = formProps

  return (
    <>
      <If condition={!isMobile} renderIf={<Header />} />
      <If
        condition={isLoading}
        renderIf={
          nextFormStep === "verification-code-generation" ? (
            <Loader variant="spinner-only" />
          ) : (
            <Loader />
          )
        }
      />
      <div className="step step--vehicle-purchase-period">
        <div className="step__container step__container--vehicle-purchase-period">
          <StepAsideImage classesSufix="vehicle-purchase-period" />

          <div className="step__aside-content step__aside-content--vehicle-purchase-period">
            <div className="step__content step__content--vehicle-purchase-period">
              <br />
              <If
                condition={isMobile}
                renderIf={
                  <Text
                    size="md"
                    weight="400"
                    className="step__vehicle-purchase-period-heading"
                  >
                    data de previsão
                  </Text>
                }
              />
              <Stepper index={1} max={2} />
              <Text
                className="step__title step__title--vehicle-purchase-period"
                as="h1"
                size="xxl"
              >
                você ja tem uma data para realizar esse sonho?
              </Text>

              <form className="step__form step__form--vehicle-purchase-period">
                <PurchasePeriod control={control} hasTitle={false} />

                <Sender
                  isNextDisabled={!isValid}
                  onNext={handleSubmit(onSubmit)}
                  nextLabel="avançar"
                />
                <br />
                <br />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default VehiclePurchasePeriod
