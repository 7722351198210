import { useContext, useEffect } from "react"

// Contexts
import { Context } from "contexts/simulation"

// Utils
import getPageInfo from "utils/getPageInfo"

const useSimulationLayout = () => {
  const { simulation } = useContext(Context)
  const { pageAlias: currentFormStep } = getPageInfo(
    simulation?.currentFormStep
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      document.querySelector(".header")?.scrollIntoView({ behavior: "smooth" })
    }, 300)

    return () => {
      clearTimeout(timeout)
    }
  }, [currentFormStep])

  return { currentFormStep }
}

export default useSimulationLayout
