import { VehiclesIcon } from "@garantidos/components"

// Components
import { Button } from "@mobi/ds"

// Data
import { desktopFiltersOptions } from "./dataContent"
// Styles
import "./index.scss"
import classnames from "classnames"
import { AutoWidthCarousel } from "components/AutoWidthCarousel"

export function DesktopFiltersButtons({ setOpenedFilter, openedFilter }) {
	function handleOpenFilter(filterKey) {
		setOpenedFilter((currentOpenedFilter) =>
			currentOpenedFilter === filterKey ? "" : filterKey
		)
	}

	return (
		<div className="step-deal__filters">
			<div className="step-deal__filters-carousel">
				<AutoWidthCarousel
					containerSelector=".step-deal__filters-carousel"
					gap={10}
				>
					{desktopFiltersOptions.map(
						({ key, title, icon = "icon-arrow-down" }) => {
							const isFilterActived = key === openedFilter

							return (
								<Button
									key={key}
									variant="secondary"
									fluid
									rightIcon={
										isFilterActived ? (
											<VehiclesIcon
												id="icon-close"
												width="12px"
												height="12px"
											/>
										) : (
											<VehiclesIcon id={icon} width="12px" height="12px" />
										)
									}
									onClick={() => handleOpenFilter(key)}
									className={classnames("step-deal__filter-button", {
										"step-deal__filter-button--actived": isFilterActived
									})}
								>
									{title}
								</Button>
							)
						}
					)}
				</AutoWidthCarousel>
			</div>
		</div>
	)
}
