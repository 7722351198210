//Hooks
import useTimer from "./useTimer"

//Utils
import getSecondsFormatted from "@mobi/utils/formatters/time"

// Components
import { Button, Text } from "@mobi/ds"
import If from "@mobi/components/If"

//Style
import "./index.scss"

const Timer = ({ onResendClick }) => {
  const { isRunning, timeCounter, handleRestartTimeCounter } = useTimer()
  const { minutes, seconds } = timeCounter

  return (
    <>
      {" "}
      <Button
        type="button"
        className="button-resend"
        onClick={() => {
          handleRestartTimeCounter()
          onResendClick()
        }}
        disabled={isRunning}
      >
        não recebeu o sms? clique aqui.
      </Button>
      <If
        condition={isRunning}
        renderIf={
          <Text as="p" size="sm" weight="400" className="expiration-warning">
            <If
              condition={
                (minutes > 0 && seconds >= 0) || (minutes === 0 && seconds > 0)
              }
              renderIf={
                <>
                  você pode pedir um novo código em {minutes}:
                  {getSecondsFormatted(seconds)}.
                  <br />
                  lembrando que seu código, tem validade de 90 dias.
                </>
              }
            />
          </Text>
        }
      />
    </>
  )
}

export default Timer
