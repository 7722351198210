import { useState, useContext, useEffect } from "react"
import { HOMEPATH } from "gatsby-env-variables"

// Contexts
import { Context } from "contexts/simulation"
import { useStep } from "@mobi/libraries/step"
import { navigateWithQueryString } from "utils/browser"

// Tracking
import analytics from "@mobi/libraries/analytics"

const useVehicleDealProperties = () => {
  const { next } = useStep()
  const { setSimulation } = useContext(Context)

  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const updateLocalStep = (updatedSimulation) => {
    const data = updatedSimulation?.data
    const nextStep = data?.nextFormStep
    setSimulation({
      ...data,
      currentFormStep: nextStep,
    })
    next(nextStep)
  }

  const onPrev = () => {
    navigateWithQueryString(HOMEPATH)
  }

  useEffect(() => {
    analytics.track("pageLoad", {})
  }, [])

  return {
    isLoading,
    setIsLoading,
    updateLocalStep,
    onPrev,
    isSubmitting,
    setIsSubmitting,
  }
}

export default useVehicleDealProperties
