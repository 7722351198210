import { forwardRef, useImperativeHandle } from "react"

import { VehiclesIcon } from "@garantidos/components"
// Utils
import { simulation } from "@garantidos/data"

// Components
import { Button, Input, Link, Select, Text } from "@mobi/ds"
// Tracking
import analytics from "@mobi/libraries/analytics"
import numberFormatter from "@mobi/utils/formatters/number"

// Style
import "./index.scss"
//Hooks
import useFinancingSettings from "./useFinancingSettings"
import classnames from "classnames"
import Loader from "components/Loader"
import Switch from "components/Switch"
import ArrowRightIcon from "images/icons/icon-arrow-right.svg"
import PropTypes from "prop-types"

import FinanceInsuranceModal from "./components/FinanceInsuranceModal"

const { getProbabilityFlag } = simulation

const FinancingSettings = forwardRef(
	(
		{
			onFinishSimulation,
			stepData,
			formProps,
			isLoading,
			requestNewSimulation,
			onModalOpen,
			installments,
			isChangesBlocked
		},
		ref
	) => {
		const {
			setResetCurrencyField,
			setAriaLive,
			setCurrencyDescription,
			getAcceptableDownPayment,
			setIsInsuranceModalOpen,
			conditions,
			resetCurrencyField,
			downPayment,
			minAcceptableDownPayment,
			maxAcceptable,
			debouncedRequestNewSimulation,
			currencyDescription,
			isInsuranceModalOpen,
			financeInsuranceInstallmentValue
		} = useFinancingSettings({
			stepData,
			formProps,
			requestNewSimulation,
			installments
		})

		useImperativeHandle(ref, () => ({
			getAcceptableDownPayment
		}))

		const {
			formState: { isValid }
		} = formProps

		return (
			<div
				className={classnames("price-simulator", {
					[getProbabilityFlag(conditions)]: true,
					"price-simulator--changes-blocked": isChangesBlocked
				})}
			>
				{isLoading && <Loader canBlockFocus={false} />}
				<div className="price-simulator__header">
					<Text className="price-simulator__label" size="xxl">
						{stepData.vehicleDealProperties?.brandName}{" "}
						{stepData.vehicleDealProperties?.modelName}
					</Text>
					<Text
						as="p"
						className="price-simulator__label-value"
						size="xxl"
						weight="400"
					>
						valor do veículo
					</Text>
					<Text
						as="p"
						className="price-simulator__value"
						size="xl"
						weight="700"
					>
						R$ {numberFormatter({ value: stepData?.vehicleValue })}
					</Text>
				</div>
				<div className="price-simulator__content">
					<Text
						as="p"
						size="lg"
						weight="400"
						className="price-simulator__conditions-title"
					>
						simule outras condições
					</Text>
					<Text
						as="p"
						size="md"
						weight="400"
						className="price-simulator__conditions-description"
					>
						{isChangesBlocked
							? "no momento você precisará fazer a simulação com os valores de entrada e número de parcelas sugerido pelo icarros, mas não se preocupe, você poderá mudar as condições assim que falar com o lojista"
							: "aqui você pode alterar o valor da entrada e escolher o número de parcelas pré-aprovadas que quer pagar."}
					</Text>

					<Input.Currency
						name="downPayment"
						label="valor de entrada"
						autoComplete="on"
						control={formProps.control}
						shouldResetOn={resetCurrencyField}
						defaultValue={downPayment}
						rules={{
							min: minAcceptableDownPayment,
							max: 1200000
						}}
						description={currencyDescription}
						validators={{ min: "", max: "", required: "" }}
						disabled={isChangesBlocked || isLoading}
						onChange={(value) => {
							const downPaymentFromDom = value

							const isLessThanMinDownPaymentFilledOnForm =
								downPaymentFromDom < minAcceptableDownPayment

							const isMoreThanMaxAcceptable = downPaymentFromDom > maxAcceptable

							const isValidValue =
								!isLessThanMinDownPaymentFilledOnForm &&
								!isMoreThanMaxAcceptable

							const downPaymentWasChanged = downPayment !== downPaymentFromDom

							debouncedRequestNewSimulation({
								downPayment: downPaymentFromDom,
								isValidValue,
								downPaymentWasChanged
							})

							setCurrencyDescription("")
							setAriaLive(true)
						}}
						onBlur={async (value) => {
							const isToRequestNewSimulation =
								value !== stepData?.defaultDownPaymentRaw

							if (isToRequestNewSimulation) {
								const acceptableDownPayment =
									getAcceptableDownPayment(downPayment)

								formProps.setValue("downPayment", acceptableDownPayment)
								await requestNewSimulation(acceptableDownPayment)
							}

							setResetCurrencyField(
								(oldResetCurrencyField) => oldResetCurrencyField + 1
							)
						}}
					/>

					<div className="price-simulator__insurance-container">
						<div className="price-simulator__insurance-content">
							<Switch
								name="financeProtectionInsurance"
								control={formProps.control}
								onChange={async () => {
									await requestNewSimulation(downPayment)
								}}
							/>

							<label
								htmlFor="financeProtectionInsurance"
								className="price-simulator__insurance-texts"
							>
								<p className="price-simulator__insurance-title">
									crédito protegido
								</p>
								<p className="price-simulator__insurance-description">
									garantia em emergências
								</p>
							</label>

							<VehiclesIcon
								id="icon-finance-insurance"
								className="price-simulator__insurance-icon"
							/>
						</div>

						<Link
							className="price-simulator__insurance-button"
							href=""
							onClick={(e) => {
								e.preventDefault()

								const webElement = {
									buttonName: "AbrirModalCreditoProtegido",
									buttonText: "entenda o crédito protegido",
									location: "Simulation"
								}
								analytics.track("buttonClickSegment", webElement)

								setIsInsuranceModalOpen(true)
							}}
						>
							entenda o crédito protegido
						</Link>
					</div>

					<FinanceInsuranceModal
						isOpened={isInsuranceModalOpen}
						onCloseClick={() => setIsInsuranceModalOpen(false)}
					/>

					<Select
						name="installments"
						label="número de parcelas"
						minSearch={2}
						options={installments}
						description={
							financeInsuranceInstallmentValue
								? `(R$ ${financeInsuranceInstallmentValue} ao mês de seguro)`
								: undefined
						}
						disabled={isChangesBlocked || isLoading}
						onChange={async ({ value, label }) => {
							const numberOfInstallments = value
							formProps.setValue(
								"numberOfInstallments",
								parseInt(numberOfInstallments)
							)
							formProps.setValue("installments", label)

							await requestNewSimulation(downPayment, numberOfInstallments)

							setResetCurrencyField(
								(oldResetCurrencyField) => oldResetCurrencyField + 1
							)
						}}
						required={true}
						control={formProps.control}
						formProps={formProps}
						validators={{
							rules: {
								validate: {
									isValidValue: (conditionValue) =>
										installments.some((objItem) =>
											(objItem.label || "").startsWith(
												(conditionValue || "").split("x")[0]
											)
										)
								}
							}
						}}
					/>
				</div>

				<div className="price-simulator__footer">
					<Link
						className="price-simulator__button-cet"
						href=""
						onClick={(e) => {
							e.preventDefault()

							const webElement = {
								buttonName: "AbrirModalCET",
								buttonText: "Detalhes do Custo Efetivo Total (CET)",
								location: "Simulation"
							}
							analytics.track("buttonClickSegment", webElement)

							onModalOpen()
						}}
					>
						ver custo efetivo total (CET)
					</Link>
				</div>
				<div className="price-simulator__download-holder">
					<Button
						className={classnames({
							"-loading": isLoading
						})}
						id="price-simulator__end-simulation-button"
						onClick={() => {
							onFinishSimulation()
						}}
						aria-busy={isLoading}
						type="button"
						fluid
						rightIcon={<ArrowRightIcon />}
						disabled={!isValid}
					>
						finalizar simulação
					</Button>
				</div>
			</div>
		)
	}
)

FinancingSettings.displayName = "FinancingSettings"

FinancingSettings.propTypes = {
	onFinishSimulation: PropTypes.func,
	stepData: PropTypes.object.isRequired,
	formProps: PropTypes.object.isRequired,
	description: PropTypes.string,
	isLoading: PropTypes.bool.isRequired,
	requestNewSimulation: PropTypes.func.isRequired,
	isDealer: PropTypes.bool,
	onModalOpen: PropTypes.func.isRequired,
	installments: PropTypes.array.isRequired,
	setSimulationSuccess: PropTypes.func
}

export default FinancingSettings
