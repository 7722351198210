import { useState, useEffect, useContext, useCallback } from "react"
import { useForm } from "react-hook-form"

// Services
import services from "services"
import entities from "@entities"

// Utils
import { fillForm } from "utils/forms"
import { getUUIDFromUrl, resetFocus } from "utils/browser"

// Contexts
import { Context } from "contexts/simulation"

// Tracking
import analytics from "@mobi/libraries/analytics"

// Hooks
import { getFromSessionStorage, saveOnSessionStorage } from "utils/storage"

export const initialYearSelectDefaultValue = "de"
export const finalYearSelectDefaultValue = "até"

const useVehicleDealPropertiesForm = ({
  setIsLoading,
  setIsSubmitting,
  updateLocalStep,
}) => {
  const { setSimulation, simulation } = useContext(Context)
  const [vehiclesYears, setVehiclesYears] = useState([])
  const [cities, setCities] = useState([])
  const [brands, setBrands] = useState([])
  const [models, setModels] = useState([])

  const formProps = useForm({
    mode: "onChange",
    defaultValues: {
      initialPrice: null,
      finalPrice: null,
      initialYear: initialYearSelectDefaultValue,
      finalYear: finalYearSelectDefaultValue,
      brand: null,
      model: null,
      purchasePeriod: "",
      dealCity: "",
      dealState: "",
    },
  })

  const brand = formProps.watch("brand")

  const getModels = useCallback(async (brandId) => {
    if (brandId) {
      const newModels = await services.vehicles.getModels({
        brand: brandId,
      })

      setModels(newModels)

      return newModels
    }

    return []
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setIsLoading(true)
    resetFocus()

    const getSimulation = async () => {
      const response = await services.vehicles.getSimulation().catch(() => {
        setIsLoading(false)
      })
      const responseVehicles = response?.vehicleDealProperties

      await getYears({ setVehiclesYears }).catch(() => {
        setIsLoading(false)
      })

      const vehiclesBrands = await services.vehicles.getBrands()
      setBrands(vehiclesBrands)
      const brandValue = vehiclesBrands.find(
        (item) => item.value === responseVehicles.brandId
      )

      let modelValue = null

      if (responseVehicles.brandId) {
        const vehiclesModels = await getModels(responseVehicles.brandId)
        modelValue = vehiclesModels.find(
          (item) => item.value === responseVehicles.modelId
        )
      }

      const initialPrice = getFromSessionStorage({ name: "initialPrice" })
      const finalPrice = getFromSessionStorage({ name: "finalPrice" })
      const initialYear = getFromSessionStorage({ name: "initialYear" })
      const finalYear = getFromSessionStorage({ name: "finalYear" })

      if (responseVehicles) {
        fillForm({
          simulation: {
            ...responseVehicles,
            brand: brandValue ? brandValue : null,
            model: modelValue ? modelValue : null,
            initialPrice,
            finalPrice,
            initialYear: initialYear || initialYearSelectDefaultValue,
            finalYear: finalYear || finalYearSelectDefaultValue,
          },
          formProps,
        })
        setIsLoading(false)

        setSimulation({
          ...response,
          ...{
            vehicleDealProperties: {
              ...(response.vehicleDealProperties || {}),
              initialPrice: initialPrice ? initialPrice : undefined,
              finalPrice: finalPrice ? finalPrice : undefined,
            },
          },
        })
      }

      setIsLoading(false)
      return responseVehicles
    }

    getSimulation()
  }, [])

  useEffect(() => {
    ;(async () => {
      const { value: brandId } = brand || {}
      if (brandId) {
        await getModels(brandId)
      }
    })()
  }, [brand, getModels])

  function getMinMaxYears({ initialYear, finalYear }) {
    if (initialYear > finalYear) {
      return { initialYear: finalYear, finalYear: initialYear }
    }

    return { initialYear, finalYear }
  }

  const onSubmit = async (payload) => {
    setIsSubmitting(true)

    const { initialYear, finalYear } = getMinMaxYears(payload)

    saveOnSessionStorage({
      name: "initialPrice",
      value: payload.initialPrice,
    })
    saveOnSessionStorage({
      name: "finalPrice",
      value: payload.finalPrice,
    })
    saveOnSessionStorage({
      name: "initialYear",
      value: initialYear,
    })
    saveOnSessionStorage({
      name: "finalYear",
      value: finalYear,
    })

    const updatedSimulation = await services.vehicles
      .update(payload, { sourceUrl: window?.location?.href })
      .catch((err) => {
        console.log(err)
        setIsSubmitting(false)
      })

    if (updatedSimulation?.status === 200) {
      analytics.track("vehicleDealProperties", {
        ...payload,
        brand: payload.brand?.label,
        model: payload.model?.label,
        city: payload.dealCity,
      })
      updateLocalStep(updatedSimulation)
    }
  }

  const onSearchCities = async (city, filters) => {
    const search = { city: city.search }
    const response = await services.places.getCities(search, filters)
    if (response) return response
  }

  return {
    setIsLoading,
    onSearchCities,
    setCities,
    cities,
    brands,
    models,
    formProps,
    simulation,
    setSimulation,
    vehiclesYears,
    onSubmit,
    brand,
  }
}

const getYears = async ({ setVehiclesYears }) => {
  const uuid = getUUIDFromUrl()
  if (!uuid) return Promise.reject()
  const response = await services.vehicles.getYears({ [uuid.name]: uuid.value })
  const years = response
  const filteredYears = years.filter(
    (item, index, acc) => acc.findIndex((t) => t.value === item.value) === index
  )
  setVehiclesYears(filteredYears)
  return years
}

export default useVehicleDealPropertiesForm
