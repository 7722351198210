// Components
import { useForm } from "react-hook-form"

import { VehiclesIcon } from "@garantidos/components"

import { Button, Radio, Text } from "@mobi/ds"

// Data
import { sortOptions } from "./dataContent"
// Styles
import "./index.scss"

export function SortContent({ getValues, onSubmitFilter, onClose }) {
	const { control, handleSubmit } = useForm({
		defaultValues: { sort: getValues("sort") }
	})

	const onSubmit = handleSubmit(async (data) => {
		onSubmitFilter(data)
		onClose()
	})

	return (
		<>
			<div className="filters-drawer__content">
				<Text size="lg" weight="400" className="filters-drawer__title">
					ordernar por
				</Text>
				<fieldset>
					{sortOptions.map(({ value, label }) => (
						<Radio
							key={value}
							id={value}
							value={value}
							name="sort"
							label={label}
							required={true}
							control={control}
						/>
					))}
				</fieldset>
			</div>
			<footer className="filters-drawer__footer filters-drawer__footer--sort">
				<Button
					rightIcon={<VehiclesIcon id="icon-arrow-right" />}
					onClick={onSubmit}
				>
					ordenar
				</Button>
			</footer>
		</>
	)
}
