const getPageInfo = (alias) => {
  const infosMap = {
    "vehicle-deal-properties": {
      pageAlias: "vehicle-deal-properties",
      pageTitle: "Sobre o veículo",
    },
    "vehicle-purchase-period": {
      pageAlias: "vehicle-purchase-period",
      pageTitle: "Data de previsão",
    },
    deal: {
      pageAlias: "deal",
      pageTitle: "Escolha seu veículo",
    },
    "verification-code-generation": {
      pageAlias: "verification-code-generation",
      pageTitle: "Confirmação com SMS",
    },
    "verification-code-confirmation": {
      pageAlias: "verification-code-generation",
      pageTitle: "Confirmação com SMS",
    },
    simulation: {
      pageAlias: "simulation",
      pageTitle: "Resumo da simulação",
    },
    "simulation-update": {
      pageAlias: "simulation",
      pageTitle: "Resumo da simulação",
    },
    "simulation-deal": {
      pageAlias: "simulation",
      pageTitle: "Resumo da simulação",
    },
  }

  return infosMap[alias] || {}
}

export default getPageInfo
