export const finalPlateOptions = [
  {
    value: "1|2",
    text: "1 e 2",
  },
  {
    value: "3|4",
    text: "3 e 4",
  },
  {
    value: "5|6",
    text: "5 e 6",
  },
  {
    value: "7|8",
    text: "7 e 8",
  },
  {
    value: "9|0",
    text: "9 e 0",
  },
]
