// Components
import { VehiclesIcon } from "@garantidos/components"

import { Text } from "@mobi/ds"

//Style
import "./index.scss"

const NextSteps = ({ items }) => {
	return (
		<div className="nextSteps">
			<Text className="nextSteps__title" as="p" size="xl" weight="400">
				próximos passos
			</Text>
			<div className="nextSteps__steps">
				{items.map(({ icon, paragraph }, key) => {
					return (
						<div className="nextSteps__item" key={key}>
							<div className="nextSteps__icon-wrapper">
								<VehiclesIcon className="nextSteps__icon" id={icon} />
							</div>
							<div className="nextSteps__text">
								<Text className="nextSteps__paragraph" as="p" size="md">
									{paragraph}
								</Text>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}
export default NextSteps
