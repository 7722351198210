import { useState, useContext, useEffect } from "react"
import { useForm } from "react-hook-form"

// Utils
import { fillForm } from "utils/forms"
import { updateToken } from "services/auth"
import { saveOnSessionStorage, getFromSessionStorage } from "utils/storage"
import { gtm } from "utils/tracking"

// Contexts
import { Context } from "contexts/simulation"
import { useStep } from "@mobi/libraries/step"

// Services
import services from "services"

// Tracking
import analytics from "@mobi/libraries/analytics"

const useVehiclePurchasePeriod = () => {
  const { next } = useStep()
  const { setSimulation } = useContext(Context)
  const [isLoading, setIsLoading] = useState(false)
  const [nextFormStep, setNextFormStep] = useState("")

  const isClosedFlow = getFromSessionStorage({ name: "isClosedFlow" }) || false

  const formProps = useForm({
    mode: "onChange",
    defaultValues: {
      purchasePeriod: "",
    },
  })

  const onSubmit = async (payload) => {
    setIsLoading(true)

    const updatedSimulation = await services.vehiclePurchasePeriod
      .update(payload, { sourceUrl: window?.location?.href })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })

    if (updatedSimulation?.status === 200) {
      analytics.track("purchasePeriod", payload)
      updateLocalStep(updatedSimulation)
    }
  }

  const updateLocalStep = (updatedSimulation) => {
    const data = updatedSimulation?.data
    const nextStep = data?.nextFormStep

    if (data?.auth) {
      updateToken(data.auth)
    }
    setSimulation({
      ...data,
      currentFormStep: nextStep,
    })
    next(nextStep)
  }

  useEffect(() => {
    setIsLoading(true)

    const getSimulation = async () => {
      const response = await services.vehiclePurchasePeriod
        .getSimulation()
        .catch(() => {
          setIsLoading(false)
        })

      saveOnSessionStorage({
        name: "phone",
        value: response.data?.lead?.phone || "",
      })

      const { nextFormStep, vehicleDealProperties } = response

      setNextFormStep(nextFormStep)

      const gtmParamers = {
        event: "Simulation_Started",
        clicked_on_financing_button: "true",
        clicked_on_message_button: "false",
        vehicle_brand: vehicleDealProperties.brandName,
        vehicle_model: vehicleDealProperties.modelName,
        vehicle_year: vehicleDealProperties.vehicleYear.toString(),
        vehicle_price: vehicleDealProperties.vehicleValue.toString(),
        vehicle_km: vehicleDealProperties.vehicleKm,
        vehicle_state: vehicleDealProperties.dealState,
        vehicle_city: vehicleDealProperties.dealCity,
        deal_id: vehicleDealProperties.dealID.toString(),
        icConversionId: response.gtm.icConversionId.toString(),
        icFeiraoIcarros: response.gtm.feirao.toString(),
        icTypeConversion: response.gtm.conversionType,
        icAnuncianteTipo: response.gtm.seller_type,
        userEmail: response.gtm.email,
        userPhone: response.gtm.phoneNumber,
        icUserScore: response.gtm.userScore.toString(),
        icEmail: response.gtm.icEmail,
        origemConversao: response.gtm.origemConversao,
      }
      if (isClosedFlow) {
        gtm(gtmParamers)
        analytics.track("leadStartFechada", response)
      }

      if (response?.data) {
        fillForm({
          purchasePeriod:
            response.data?.vehicleDealProperties?.purchasePeriod || "",
        })
      }

      setIsLoading(false)
    }

    getSimulation()
    analytics.track("pageLoad", {})
  }, [])

  return { isLoading, onSubmit, formProps, nextFormStep }
}

export default useVehiclePurchasePeriod
