export const periodList = [
  {
    id: "oneweek",
    value: "ONE_WEEK",
    text: "em 1 semana",
  },
  {
    id: "fifteen",
    value: "FIFTEEN_DAYS",
    text: "em 15 dias",
  },
  {
    id: "onemonth",
    value: "ONE_MONTH",
    text: "em 1 mês",
  },
  {
    id: "idk",
    value: "NOT_SURE",
    text: "estou só simulando",
  },
]
