//Components
import { Radio, Text } from "@mobi/ds"

//Style
import "./index.scss"

// Data
import { periodList } from "./dataContent"

export function PurchasePeriod({ control, hasTitle = true }) {
  return (
    <fieldset className="purchase-period">
      {hasTitle && (
        <>
          <br />
          <Text size="xl" weight="800">
            Você já tem uma data para realizar esse sonho?
          </Text>
        </>
      )}
      {periodList.map(({ text, id, value }) => {
        return (
          <Radio
            key={id}
            id={id}
            value={value}
            name="purchasePeriod"
            label={text}
            required={true}
            control={control}
          />
        )
      })}
    </fieldset>
  )
}
