// Components
import { Button, Checkbox, Text } from "@mobi/ds"
import { useForm } from "react-hook-form"

export const vehicleConditionFilterClearValues = { newVehicles: false }

export function VehicleConditionContent({
  getValues,
  onSubmitFilter,
  onClose,
}) {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: { newVehicles: getValues("newVehicles") },
  })

  const newVehicles = watch("newVehicles")
  const hasValue = !!newVehicles

  const onClear = () => {
    onSubmitFilter(vehicleConditionFilterClearValues)
    onClose()
  }

  const onSubmit = handleSubmit(async (data) => {
    onSubmitFilter(data)
    onClose()
  })

  return (
    <>
      <div className="filters-drawer__content">
        <Text size="lg" weight="400" className="filters-drawer__title">
          condição do veículo
        </Text>
        <Checkbox name="newVehicles" label="0km" control={control} />
      </div>
      <footer className="filters-drawer__footer">
        <Button variant="secondary" onClick={onClear} disabled={!hasValue}>
          limpar
        </Button>
        <Button onClick={onSubmit} disabled={!hasValue}>
          filtrar
        </Button>
      </footer>
    </>
  )
}
