// Components
import { VehiclesIcon } from "@garantidos/components"

import If from "@mobi/components/If"
import { Text } from "@mobi/ds"
// Utils
import isMobile from "@mobi/utils/browser/isMobile"

//Style
import "./index.scss"
//Hooks
import useVerificationCodeGeneration from "./useVerificationCodeGeneration"
import classnames from "classnames"
import { BackButton } from "components/BackButton"
import Header from "components/Header"
import { InputToken } from "components/InputToken"
import Loader from "components/Loader"
import Sender from "components/Sender"
import Stepper from "components/Stepper"
import Timer from "components/Timer"

import { StepAsideImage } from "../components/StepAsideImage"

const VerificationCodeGeneration = () => {
	const {
		onSubmit,
		onPrev,
		resendCode,
		phoneNumber,
		setSmsStatus,
		smsStatus,
		formProps,
		isLoading,
		isClosedFlow
	} = useVerificationCodeGeneration()

	const {
		handleSubmit,
		formState: { isValid }
	} = formProps

	const feedbacks = {
		success: {
			icon: "icon-check-circle-base",
			title: "SMS enviado",
			text: "fique atento ao seu celular, copie e digite o código atualizado.",
			className: "step__notification--success"
		}
	}

	const validators = {
		messages: {
			invalidCode: "código inválido"
		}
	}

	return (
		<>
			<If condition={!isMobile} renderIf={<Header />} />
			<If condition={isLoading} renderIf={<Loader variant="spinner-only" />} />
			<div className="step step--verification-code-generation">
				<div className="step__container step__container--verification-code-generation">
					<StepAsideImage hasText={false} />
					<div className="step__aside-content step__aside-content--verification-code-generation">
						<div className="step__content step__content--verification-code-generation">
							<br />

							<If
								condition={smsStatus && feedbacks[smsStatus]}
								renderIf={
									<>
										<div
											className={classnames("step__notification", {
												[feedbacks[smsStatus]?.className]: true
											})}
											role="alert"
										>
											<VehiclesIcon
												id={feedbacks[smsStatus]?.icon}
												className="step__notification__icon"
											/>
											<div className="step__notification__text">
												<Text size="sm">{feedbacks[smsStatus]?.title}</Text>
												<Text size="sm">{feedbacks[smsStatus]?.text}</Text>
											</div>
											<VehiclesIcon
												id="icon-close"
												className="step__notification__iclose"
												onClick={() => setSmsStatus(false)}
											/>
										</div>
									</>
								}
								renderElse={
									<>
										<BackButton text="voltar" onClick={onPrev} />
										<Stepper
											index={isClosedFlow ? 2 : 3}
											max={isClosedFlow ? 2 : 3}
										/>
									</>
								}
							/>
							<Text
								className="step__title step__title--verification-code-generation"
								as="h2"
								size="xxl"
								weight={700}
							>
								validação de segurança
							</Text>
							<Text
								className="step__text step__text--verification-code-generation"
								size="md"
							>
								Para continuar com a simulação digite o código que enviamos por
								SMS para o número {phoneNumber}
							</Text>

							<form
								className="step__form step__form--verification-code-generation"
								onSubmit={handleSubmit(onSubmit)}
							>
								<InputToken
									name="code"
									label="código"
									size={4}
									formProps={formProps}
									validators={validators}
									shouldResetOn={null}
									type="tel"
								/>
								<Timer onResendClick={() => resendCode()} />
								<br />
								<Sender
									isNextDisabled={!isValid}
									hasPrev={false}
									onNext={handleSubmit(onSubmit)}
									nextLabel="avançar"
								/>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default VerificationCodeGeneration
