import numberFormatter from "@mobi/utils/formatters/number"

const getProbabilityFlag = ({
	isBiggerThanMax,
	isMaxDownPayment,
	hasHighChance,
	needAnalysis,
	isMinAcceptableDownPayment,
	isLessThanDefaultValue,
	isLessThanMin
}) => {
	const smallChancesFlag = "-small"
	const bigChancesFlag = "-big"
	const minValueFlag = "-min"
	if (isBiggerThanMax) return smallChancesFlag
	if (isMaxDownPayment) return bigChancesFlag
	if (hasHighChance) return bigChancesFlag
	if (needAnalysis) return smallChancesFlag
	if (isMinAcceptableDownPayment) return minValueFlag
	if (isLessThanDefaultValue) return smallChancesFlag
	if (isLessThanMin) return minValueFlag
	return ""
}

const getDownpaymentLabel = ({
	maxAcceptable,
	isBiggerThanMax,
	isMaxDownPayment,
	hasHighChance,
	needAnalysis,
	isMinAcceptableDownPayment,
	isLessThanMin,
	minAcceptableDownPayment
}) => {
	const labelMap = {
		1: `o valor máximo de entrada é de ${numberFormatter({
			value: maxAcceptable
		})}`,
		2: "esse é o valor máximo de entrada, tem grande chance de aprovação",
		3: "esse valor tem grande chance de aprovação",
		4: "esse valor é a nossa sugestão de entrada",
		5: "esse valor tem menos chance de aprovação e depende de uma análise",
		6: "esse é o valor mínimo de entrada, tem baixa chance de aprovação e depende de uma análise",
		7: `o valor mínimo de entrada é de ${numberFormatter({
			value: minAcceptableDownPayment
		})}`
	}
	if (isBiggerThanMax) return labelMap[1]
	if (isMaxDownPayment) return labelMap[2]
	if (hasHighChance) return labelMap[3]
	if (needAnalysis) return labelMap[5]
	if (isMinAcceptableDownPayment) return labelMap[6]
	if (isLessThanMin) return labelMap[7]
	return labelMap[""]
}

export default { getProbabilityFlag, getDownpaymentLabel }
