import { StepProvider, Step } from "@mobi/libraries/step"

// Steps
import VehicleDealProperties from "pages/Form/steps/VehicleDealProperties"
import VerificationCodeGeneration from "pages/Form/steps/VerificationCodeGeneration"
import Simulation from "pages/Form/steps/Simulation"
import Deal from "pages/Form/steps/Deal"
import FeedbackProposal from "pages/Form/steps/FeedbackProposal"
import VehiclePurchasePeriod from "pages/Form/steps/VehiclePurchasePeriod"

const steps = {
  "vehicle-deal-properties": {
    component: VehicleDealProperties,
    title: "dados do veículo",
  },
  "vehicle-purchase-period": {
    component: VehiclePurchasePeriod,
    title: "data de previsão",
  },
  deal: {
    component: Deal,
    title: "escolha um veículo",
  },
  "verification-code-generation": {
    component: VerificationCodeGeneration,
    title: "validação de segurança",
  },
  "verification-code-confirmation": {
    component: VerificationCodeGeneration,
    title: "validação de segurança",
  },
  simulation: {
    component: Simulation,
    title: "resultado da simulação",
  },
  "simulation-update": {
    component: Simulation,
    title: "resultado da simulação",
  },
  "simulation-deal": {
    component: Simulation,
    title: "resultado da simulação",
  },
  "feedback-proposal": {
    component: FeedbackProposal,
    title: "resultado da simulação",
  },
}

const Forms = ({ current }) => {
  if (!current) {
    return null
  }

  return (
    <StepProvider steps={steps} start={current}>
      <Step />
    </StepProvider>
  )
}

export default Forms
