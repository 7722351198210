import { useState } from "react"
import { useController } from "react-hook-form"

// Utils
import mergeDeep from "@mobi/utils/object/mergeDeep"

export function useSheetSelectField({
  name,
  control,
  rules: propRules = {},
  required = false,
  onChange: propOnChange,
}) {
  const rules = mergeDeep(propRules, { required })
  const {
    field: { onBlur, onChange, value },
  } = useController({ name, control, rules })

  const [isOpen, setIsOpen] = useState(false)

  function onOpen() {
    setIsOpen(true)
  }

  function onClose() {
    setIsOpen(false)

    onBlur()
  }

  function onSelectItem(item) {
    onChange(item)

    propOnChange && propOnChange(item)
  }

  return { isOpen, onOpen, onClose, value, onSelectItem }
}
