import classNames from "classnames"
import Img from "components/Img"

//Components
import { Text } from "@mobi/ds"

import "./index.scss"

export function StepAsideImage({ classesSufix, hasText = true }) {
  return (
    <div
      className={classNames("step__aside-image", {
        [`step__aside-image--${classesSufix}`]: !!classesSufix,
      })}
      aria-hidden="true"
    >
      {hasText && (
        <div className="step__wrapper-text">
          <Text as="h1" size="xxl" weight="900">
            financie seu próximo carro com icarros
          </Text>
          <Text as="p" size="md">
            Quer financiar um carro novo ou usado? Faça uma simulação e veja o
            valor das parcelas na hora.
          </Text>
        </div>
      )}
      <Img
        file="woman-testing-car"
        lazy="true"
        className="image"
        alt=""
        role="presentation"
      />
      {hasText && <span className="step__aside-image-overlayer" />}
    </div>
  )
}
