import { useRef, useState } from "react"
import classnames from "classnames"

// Components
import Contacts from "components/Contacts"
import { Text } from "@mobi/ds"
import ModalCET from "components/ModalCET"
import { Likert } from "components/Likert"
import Loader from "components/Loader"
import If from "@mobi/components/If"
import Marketplace from "components/Marketplace"
import { BackButton } from "components/BackButton"
import Footer from "components/Footer"
import FinancingSettings from "components/FinancingSettings"
import NextSteps from "components/NextSteps"
import Header from "components/Header"

// Hooks
import useSimulation from "./useSimulation"

// Services
import { toInstallmentsList } from "services/mappers"

// Utils
import numberFormatter from "@mobi/utils/formatters/number"
import { currencyToFloat } from "@mobi/utils/formatters/currency"
import { navigateWithQueryString } from "utils/browser"

// Style
import "./index.scss"

// Data
import { marketplaceCardItems, nextStepsItems } from "./dataContent"

const Simulation = () => {
  const [isModalOpened, setIsModalOpened] = useState(false)

  const financingSettingsRef = useRef(null)

  const {
    onSubmit,
    onSend,
    getSelectedOption,
    requestNewSimulation,
    formProps,
    stepData,
    isLoading,
    isFetchingSimulation,
    isSimulationChangesBlocked,
    isClosedFlow,
  } = useSimulation()

  const seller = stepData?.seller || {}
  const sellerName =
    `${stepData?.displayName?.charAt(0).toUpperCase()}${stepData?.displayName
      ?.toLowerCase()
      .slice(1)}` || ""
  const phone = seller?.contacts?.filter((item) => item.kind === "PHONE") || []
  const whatsapp = seller?.contacts?.find(
    (item) => item.kind === "WHATSAPP"
  )?.value

  const { handleSubmit, watch } = formProps

  const getUpdatedValue = (fieldName) => watch(fieldName)

  const installments = toInstallmentsList(stepData?.availableInstallmentOptions)
  const numberOfInstallments = getUpdatedValue("numberOfInstallments")
  const selectedOption = {
    ...getSelectedOption({ installments, numberOfInstallments }),
    numberOfInstallments,
  }

  const vehicleDealProperties = {
    ...stepData,
    ...selectedOption,
  }

  const totalPayable = stepData?.totalPayable

  const iofValue = getSelectedOption({
    installments,
    numberOfInstallments,
  })?.iofValue

  const { totalAmountFinancedWithoutTax } = stepData

  const totalAmount = numberFormatter({
    value: totalAmountFinancedWithoutTax + currencyToFloat(iofValue),
  })

  const onKeyDownEnter = (keyEvent) => {
    if (keyEvent.key === "Enter") {
      keyEvent.preventDefault()
    }
  }

  const send = (values) => {
    const acceptableDownPayment =
      financingSettingsRef.current.getAcceptableDownPayment(values.downPayment)

    formProps.setValue("downPayment", acceptableDownPayment, {
      shouldValidate: true,
    })

    return onSubmit({
      ...values,
      downPayment: acceptableDownPayment,
    })
  }

  const financingSettingsProperties = {
    onFinishSimulation: onSend,
    stepData,
    formProps,
    isLoading: isFetchingSimulation,
    requestNewSimulation,
    onModalOpen: () => setIsModalOpened(true),
    installments,
    isChangesBlocked: isSimulationChangesBlocked,
  }

  return (
    <>
      <Header />

      <ModalCET
        isOpened={isModalOpened}
        onCloseClick={() => setIsModalOpened(false)}
        vehicleDealProperties={vehicleDealProperties}
        totalAmount={totalAmount}
        totalPayable={totalPayable}
      />
      <If
        condition={isLoading}
        renderIf={<Loader />}
        renderElse={
          <div
            className={classnames("simulation", {
              "simulation--blocked": isModalOpened,
            })}
          >
            <div className="simulation__container">
              {!isClosedFlow && (
                <BackButton onClick={() => navigateWithQueryString("/")} />
              )}

              <div className="simulation__title-box">
                <Text
                  className="simulation__title"
                  as="h2"
                  size="lg"
                  weight="700"
                >
                  seu crédito foi pré-aprovado!
                  <br />
                  {`agora entre em contato com a ${sellerName}`}
                </Text>
                <Text size="md" className="simulation__description">
                  <strong>Simule as melhores condições</strong> para conversar
                  com o lojista.
                </Text>
              </div>
              <div className="simulation__content">
                <div className="simulation__wrapper">
                  <form
                    className={classnames("simulation__form", {
                      "simulation__form--loading": isFetchingSimulation,
                    })}
                    onSubmit={handleSubmit(send)}
                    onKeyDown={onKeyDownEnter}
                    noValidate
                  >
                    <FinancingSettings
                      ref={financingSettingsRef}
                      {...financingSettingsProperties}
                    />
                  </form>
                </div>
                <div className="simulation__wrapper">
                  <Contacts
                    baseClass="simulation"
                    sellerName={sellerName}
                    phone={phone}
                    whatsapp={whatsapp}
                    address={stepData.streetName}
                    currentStep="simulation"
                  />
                </div>
                <div className="simulation__wrapper simulation__next-steps-wrapper">
                  <NextSteps items={nextStepsItems} />
                </div>
              </div>
              <div className="simulation__likert-container">
                <Likert />
              </div>
              <div className="simulation__marketplace">
                <Marketplace
                  cardItems={marketplaceCardItems}
                  currentStep="simulation"
                />
              </div>
              <br />
              <br />
              <br />
              <br />
            </div>

            <Footer />
          </div>
        }
      />
    </>
  )
}

export default Simulation
