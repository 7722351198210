// Components
import { Button, Radio, Text, Input } from "@mobi/ds"
import { useForm } from "react-hook-form"

// Utils
import isMobile from "@mobi/utils/browser/isMobile"

// Data
import { kilometersOptions } from "./dataContent"

import "./index.scss"

export const kilometersFilterClearValues = {
  minKm: "",
  maxKm: "",
}

export function KilometersContent({ getValues, onSubmitFilter, onClose }) {
  const parseKmInputValue = (value) => {
    if (isNaN(value) || Number(value) === 0) {
      return ""
    }

    return value.toString().replaceAll(".", "")
  }

  const formatKmInputValue = (value) =>
    value
      .toString()
      .replaceAll(".", "")
      .replaceAll(/\B(?=(\d{3})+(?!\d))/g, ".")

  function getDefaultValues() {
    const minKmDefaultValue = getValues("minKm")
    const maxKmDefaultValue = getValues("maxKm")

    const kmOptionMatched = kilometersOptions.find(
      (kmOption) =>
        kmOption.minKm === minKmDefaultValue &&
        kmOption.maxKm === maxKmDefaultValue
    )

    if (kmOptionMatched) {
      return { kmOption: kmOptionMatched.value, minKm: "", maxKm: "" }
    }

    return {
      kmOption: null,
      minKm: formatKmInputValue(minKmDefaultValue),
      maxKm: formatKmInputValue(maxKmDefaultValue),
    }
  }

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: getDefaultValues(),
  })

  const values = watch(["kmOption", "minKm", "maxKm"])
  const hasValue = values.some((value) => !!value)

  const onClear = () => {
    onSubmitFilter(kilometersFilterClearValues)
    onClose()
  }

  const onSubmit = handleSubmit(
    async ({ kmOption: kmOptionValue, minKm, maxKm }) => {
      if (kmOptionValue) {
        const kmOption = kilometersOptions.find(
          (option) => option.value === kmOptionValue
        )

        onSubmitFilter({ minKm: kmOption.minKm, maxKm: kmOption.maxKm })
      } else {
        onSubmitFilter({
          minKm: parseKmInputValue(minKm),
          maxKm: parseKmInputValue(maxKm),
        })
      }

      onClose()
    }
  )

  return (
    <>
      <div className="filters-drawer__content">
        <Text size="lg" weight="400" className="filters-drawer__title">
          {isMobile ? "defina a faixa de quilometragem" : "quilometragem"}
        </Text>
        <fieldset>
          {kilometersOptions.map(({ label, value }) => (
            <Radio
              key={value}
              id={value}
              value={value}
              name="kmOption"
              label={label}
              required={false}
              control={control}
            />
          ))}
        </fieldset>
        <div className="filters-drawer__kilometers-row">
          <Input.Text
            name="minKm"
            label=""
            required={false}
            type="tel"
            control={control}
            placeholder="de"
            description="ex. 15.000"
            validators={{
              rules: {
                validate: {
                  isNotANumber: (value) => !isNaN(value),
                },
              },
              messages: {
                isNotANumber: "Digite apenas números",
              },
            }}
            onChange={(e) => {
              const valueWithDots = formatKmInputValue(e?.target?.value)
              setValue("minKm", valueWithDots)
              setValue("kmOption", null)
            }}
          />
          <Input.Text
            name="maxKm"
            label=""
            required={false}
            type="tel"
            control={control}
            placeholder="até"
            description="ex. 30.000"
            validators={{
              rules: {
                validate: {
                  isNotANumber: (value) => !isNaN(value),
                },
              },
              messages: {
                isNotANumber: "Digite apenas números",
              },
            }}
            onChange={(e) => {
              const valueWithDots = formatKmInputValue(e?.target?.value)
              setValue("maxKm", valueWithDots)
              setValue("kmOption", null)
            }}
          />
        </div>
      </div>
      <footer className="filters-drawer__footer">
        <Button variant="secondary" onClick={onClear} disabled={!hasValue}>
          limpar
        </Button>
        <Button onClick={onSubmit} disabled={!hasValue}>
          filtrar
        </Button>
      </footer>
    </>
  )
}
