// Components
import { Fragment } from "react"

import { VehiclesIcon } from "@garantidos/components"

import { Button, Text } from "@mobi/ds"
// Tracking
import analytics from "@mobi/libraries/analytics"

import { DealSlider } from "../DealSlider"
// Styles
import "./index.scss"

const VehicleDetails = ({ selectedVehicle = {}, onClose, onNext }) => {
	const {
		price,
		sellerName,
		images,
		km,
		modelYear,
		equipments,
		dealText,
		gearDescription,
		fuelDescription,
		plateLastNumber,
		sellerCityDescription,
		sellerUfDescription,
		brand,
		model,
		versionDescription
	} = selectedVehicle

	const formattedBrandModel = `${brand} ${model}`

	const hasImages = (images || []).length > 0

	return (
		<div className="vehicle-details">
			<header className="vehicle-details__header">
				<Text size="sm" weight={400}>
					mais detalhes
				</Text>
			</header>

			<div className="vehicle-details__content">
				<Text size="xxl" weight={800} className="vehicle-details__model">
					{formattedBrandModel}
				</Text>
				<Text size="sm" weight={400}>
					{versionDescription}
				</Text>
				<Text weight={700} className="vehicle-details__price">
					{new Intl.NumberFormat("pt-BR", {
						style: "currency",
						currency: "BRL"
					}).format(price)}
				</Text>
				<Text size="sm" weight={400} className="vehicle-details__seller-name">
					{sellerName}
				</Text>
				{hasImages && (
					<DealSlider>
						{images?.map((item) => {
							return (
								<img
									src={item}
									className="vehicle-details__carousel-item"
									alt=""
									key={item}
								/>
							)
						})}
					</DealSlider>
				)}
				<ul className="vehicle-details__equipaments">
					<li className="vehicle-details__equipaments-item">
						<VehiclesIcon id="icon-check" />
						<Text as="p" size="md" weight={400}>
							Ano modelo: {modelYear}
						</Text>
					</li>
					<li className="vehicle-details__equipaments-item">
						<VehiclesIcon id="icon-check" />
						<Text as="p" size="md" weight={400}>
							Cor: {selectedVehicle.colorDescription}
						</Text>
					</li>
					<li className="vehicle-details__equipaments-item">
						<VehiclesIcon id="icon-check" />
						<Text as="p" size="md" weight={400}>
							Câmbio: {gearDescription}
						</Text>
					</li>
					<li className="vehicle-details__equipaments-item">
						<VehiclesIcon id="icon-check" />
						<Text as="p" size="md" weight={400}>
							Km: {km}
						</Text>
					</li>
					<li className="vehicle-details__equipaments-item">
						<VehiclesIcon id="icon-check" />
						<Text as="p" size="md" weight={400}>
							Cidade:{" "}
							{`${sellerCityDescription}${
								sellerUfDescription ? `, ${sellerUfDescription}` : ""
							}`}
						</Text>
					</li>
					<li className="vehicle-details__equipaments-item">
						<VehiclesIcon id="icon-check" />
						<Text as="p" size="md" weight={400}>
							{fuelDescription}
						</Text>
					</li>
					<li className="vehicle-details__equipaments-item">
						<VehiclesIcon id="icon-check" />
						<Text as="p" size="md" weight={400}>
							Final da placa: {plateLastNumber}
						</Text>
					</li>
					<li className="vehicle-details__equipaments-item">
						<VehiclesIcon id="icon-check" />
						<Text as="p" size="md" weight={400}>
							Equipamentos:{" "}
							{equipments?.map((item, index) => {
								return (
									<Fragment key={item}>
										{item}
										{index + 1 === equipments.length ? "." : ", "}
									</Fragment>
								)
							})}
						</Text>
					</li>
				</ul>
				<Text size="md" weight={400} className="vehicle-details__deal-text">
					Informações do veículo:
					<br />
					{dealText}
				</Text>
			</div>

			<footer className="vehicle-details__footer">
				<Button type="button" variant="secondary" onClick={onClose} fluid>
					voltar
				</Button>
				<Button
					type="button"
					onClick={() => {
						onClose()
						onNext(selectedVehicle, { isVehicleDetails: "true" })

						const webElement = {
							buttonName: "simular",
							buttonText: "simular",
							location: "modal do anuncio"
						}
						analytics.track("buttonClickSegment", webElement)
					}}
					fluid
				>
					simular
				</Button>
			</footer>
		</div>
	)
}

export default VehicleDetails
