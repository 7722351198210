export const kilometersOptions = [
  {
    label: "0 a 25.000 km",
    value: "0 a 25.000 km",
    minKm: null,
    maxKm: 25000,
  },
  {
    label: "25.000 a 50.000 km",
    value: "25.000 a 50.000 km",
    minKm: 25000,
    maxKm: 50000,
  },
  {
    label: "50.000 a 75.000 km",
    value: "50.000 a 75.000 km",
    minKm: 50000,
    maxKm: 75000,
  },
  {
    label: "75.000 a 100.000 km",
    value: "75.000 a 100.000 km",
    minKm: 75000,
    maxKm: 100000,
  },
  {
    label: "100.000 km ou mais",
    value: "100.000 km ou mais",
    minKm: 100000,
    maxKm: null,
  },
]
