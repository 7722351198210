import { useEffect } from "react"
import { Input } from "@mobi/ds"

export function InputToken(props) {
  const { formProps, name } = props

  const tokenValue = formProps.watch(name)

  useEffect(() => {
    function changeInputTypeByIndex(inputIndex, type, delay) {
      const inputName = `${name}-digit-${inputIndex}`

      const inputElement = document.getElementById(inputName)
      if (inputElement && inputElement.type !== type) {
        setTimeout(() => inputElement.setAttribute("type", type), delay)
      }
    }

    const inputIndex = (tokenValue || "").trim().length - 1
    if (inputIndex > -1) changeInputTypeByIndex(inputIndex, "password", 500)
    changeInputTypeByIndex(inputIndex + 1, "tel", 0)
  }, [name, tokenValue])

  useEffect(() => {
    function focusFirtsInput() {
      const inputName = `${name}-digit-0`

      const inputElement = document.getElementById(inputName)
      if (inputElement) {
        inputElement.autofocus = true
        inputElement.focus()
      }
    }

    focusFirtsInput()
  }, [])

  return (
    <Input.Token
      {...props}
      inputMode="numeric"
      autoComplete="one-time-code"
      pattern="[0-9]*"
    />
  )
}
