// Components
import { VehiclesIcon } from "@garantidos/components"

import { Button, Text } from "@mobi/ds"
// Tracking
import analytics from "@mobi/libraries/analytics"

//Style
import "./index.scss"

const Marketplace = ({ cardItems }) => {
	const prepareToTrack = (title, button) => {
		const webElement = {
			location: "Marketplace",
			buttonName: title,
			buttonText: button
		}
		analytics.track("buttonClickSegment", webElement)
	}

	return (
		<div className="marketplace">
			<Text className="marketplace__title" as="h2" size="lg" weight="700">
				melhores produtos feitos com você
			</Text>

			<div className="marketplace__wrapper">
				{cardItems.map(({ name, url, title, paragraph, button }, key) => {
					return (
						<div
							className={`marketplace__card marketplace__card__${name}`}
							key={key}
						>
							<div className="marketplace__content">
								<div className="marketplace__wrapper-text">
									<Text
										className="marketplace__title-card"
										as="h3"
										size="sm"
										weight={700}
									>
										{title}
									</Text>
									<Text className="marketplace__paragraph" size="md">
										{paragraph}
									</Text>
								</div>
								<Button
									className="marketplace__button"
									tagName="a"
									variant="outline"
									href={url}
									target="_blank"
									rightIcon={<VehiclesIcon id="icon-arrow-right" />}
									onClick={() => {
										prepareToTrack(title, button)
									}}
								>
									{button}
								</Button>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default Marketplace
