// Components
import { VehiclesIcon } from "@garantidos/components"

import { Text } from "@mobi/ds"

// Data
import { getFiltersButtons } from "./dataContent"
// Styles
import "./index.scss"
import Modal from "components/Modal"

export function MobileFiltersButtons({
	isOpen,
	onClose,
	onOpenFilter,
	getValues,
	onSubmitFilter
}) {
	const filtersButtons = getFiltersButtons({ getValues })

	const activeFilters = filtersButtons.filter(
		({ getFilterValue, getIsDirty = (filterValue) => !!filterValue }) => {
			const filterValue = getFilterValue()
			return getIsDirty(filterValue)
		}
	)

	function handleOpenFilter(filterKey) {
		onOpenFilter(filterKey)
	}

	function handleClearFilter(defaultValue) {
		onSubmitFilter(defaultValue)
	}

	return (
		<Modal
			isOpened={isOpen}
			onCloseClick={() => onClose()}
			variant="full"
			hasCloseButton={false}
		>
			<div className="modal-filters__heading">
				<button
					type="button"
					onClick={onClose}
					className="modal-filters__back-button"
				>
					<VehiclesIcon id="icon-arrow-left" />
				</button>
			</div>

			<Text className="modal-filters__title" size="xl" weight="700">
				filtros
			</Text>

			<div className="modal-filters__tags">
				{activeFilters.map(({ label, isClearable = true, defaultValue }) => (
					<div key={label} className="modal-filters__tag">
						<Text size="sm" weight="400">
							{label}
						</Text>
						{isClearable && (
							<button
								aria-label={`Limpar o filtro de ${label}`}
								className="modal-filters__tag-close"
								onClick={() => handleClearFilter(defaultValue)}
							>
								<VehiclesIcon id="icon-close" width="12px" height="12px" />
							</button>
						)}
					</div>
				))}
			</div>

			<div className="modal-filters__buttons-container">
				{filtersButtons.map(({ label, getFilterValue, filterKey }) => (
					<button
						key={filterKey}
						onClick={() => handleOpenFilter(filterKey)}
						className="modal-filters__button"
					>
						<div className="modal-filters__button-text">
							<Text size="md" weight="400">
								{label}
							</Text>
							<Text
								size="sm"
								weight="400"
								className="modal-filters__filter-value"
							>
								{getFilterValue()}
							</Text>
						</div>

						<VehiclesIcon
							id="icon-arrow-right"
							className="modal-filters__button-arrow"
						/>
					</button>
				))}
			</div>
		</Modal>
	)
}
