import { useForm } from "react-hook-form"

// Components
import { Button, Text } from "@mobi/ds"
import { SheetSelect } from "components/SheetSelect"

export function BrandContent({ getValues, onSubmitFilter, onClose, options }) {
  const { watch, handleSubmit, setValue } = useForm({
    defaultValues: { brand: getValues("brand") },
  })

  const currentValue = watch("brand")

  const onSubmit = handleSubmit((data) => {
    onSubmitFilter({
      ...data,
      model: null,
    })
    onClose()
  })

  const onSelectItem = (item) => {
    setValue("brand", item)
  }

  return (
    <>
      <div className="filters-drawer__content">
        <Text size="lg" weight="400" className="filters-drawer__title">
          marca
        </Text>
        <SheetSelect.Content
          options={options}
          name="brand"
          currentValue={currentValue?.value}
          onSelectItem={onSelectItem}
          placeholder="digite uma marca"
        />
      </div>
      <footer className="filters-drawer__footer">
        <Button onClick={onSubmit}>filtrar</Button>
      </footer>
    </>
  )
}
