import { Link } from "@mobi/ds"

export const financeInsuranceConditions = [
  {
    title: "desemprego",
    description:
      "Cobrimos 5 parcelas da sua renegociação com limite de até R$35 mil. Carência de 30 dias.",
  },
  {
    title: "incapacidade temporária",
    description:
      "Cobrimos 5 parcelas da sua renegociação com limite de até R$35 mil. Carência de 30 dias e franquia de 15 dias.",
  },
  {
    title: "morte",
    description:
      "Cobrimos o limite máximo de indenização (LMI) informado no certificado limitado até R$50 mil. Carência de 2 anos em caso de tentativa de suicídio.",
  },
  {
    title: "invalidez permanente",
    description:
      "Cobrimos o limite máximo de indenização (LMI) informado no certificado limita até R$50 mil. Carência de 2 anos em caso de tentativa de suicídio.",
  },
]

export const financeInsuranceAccordionItems = [
  {
    title: "principais exclusões",
    children: (
      <>
        <br />
        Doenças preexistentes
        <br />
        <br />
        Demissão por justa causa, pedido de demissão pelo segurado, registro em
        carteira de trabalho inferior a 12 meses.
        <br />
        <br />
        Afastamento ou repouso absoluto do segurado por período inferior a 15
        (quinze) dias.
        <br />
        <br />
        Afastamento para cirurgia plástica estética ou tratamento de
        esterilização ou fertilização.
        <br />
        <br />
        Não há cobertura para invalidez parcial, apenas invalidez total.
      </>
    ),
  },
  {
    title: "termos e condições",
    children: (
      <>
        <br />
        Para efeito de aplicação do art. 766 do Código Civil, declaro que não
        tenho conhecimento de ser portador de quaisquer das doenças ou lesões
        relevantes que exijam tratamento médico e que não estou afastado de
        minhas atividades habituais por motivo de saúde. 
        <br />
        <br />
        Caso não concorde com a declaração sobre sua saúde, siga a contratação
        sem seguro.
        <br />
        <br />
        Estou ciente de que esse seguro é opcional e que posso cancelar a
        qualquer tempo, com devolução do prêmio pago referente ao período a
        decorrer, se houver.
        <br />
        <br />
        Declaro que li e concordo com os dados da contratação das condições
        gerais do seguro, que contempla todos os benefícios e restrições.
        <br />
        <br />
        Em caso de quitação antecipada ou portabilidade do crédito, o seguro
        será automaticamente cancelado, sem prejuízo, se for o caso, da
        devolução do prêmio pago referente ao período a decorrer. Em caso de uma
        nova renegociação, e se não houver a contratação de um novo seguro, este
        seguro permanecerá ativo pelo seu prazo de vigência original,
        mantendo-se as demais características do seguro, conforme originalmente
        contratado.
        <br />
        <br />
        <Link
          href="https://www.itau.com.br/seguros/prestamista"
          target="_blank"
          rel="noreferrer"
          className="insurance-modal__link"
        >
          mais informações
        </Link>
        <br />
        <br />
        <Link
          href="https://www.itau.com.br/media/dam/m/223d5c0eb3cb1328/original/CG_ProtecaoFinanceira.pdf"
          target="_blank"
          rel="noreferrer"
          className="insurance-modal__link"
        >
          condições gerais
        </Link>
      </>
    ),
  },
]
