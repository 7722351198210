//Components
import { Text, Button } from "@mobi/ds"
import Img from "components/Img"
import If from "@mobi/components/If"

import { DealSlider } from "../DealSlider"

// Tracking
import analytics from "@mobi/libraries/analytics"

//Style
import "./index.scss"

const DealCard = ({
  item,
  onNext,
  selectVehicle = (f) => f,
  setIsModalOpened = (f) => f,
}) => {
  if (!item) {
    return null
  }

  function handleOpenMoreDetails() {
    selectVehicle(item)
    setIsModalOpened(true)
  }

  function handleSimulate() {
    selectVehicle(item)
    onNext(item)

    const webElement = {
      buttonName: "simular",
      buttonText: "simular",
      location: "card do anuncio",
    }
    analytics.track("buttonClickSegment", webElement)
  }

  const formattedPrice = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(item.price)
  const formattedCity = `${item?.sellerCityDescription}${
    item?.sellerUfDescription ? `, ${item?.sellerUfDescription}` : ""
  }`
  const formattedYears = `${item?.manufacturingYear}/${item?.modelYear}`
  const formattedKm = `${item?.km
    .toString()
    .replaceAll(/\B(?=(\d{3})+(?!\d))/g, ".")} km`
  const formattedBrandModel = `${item.brand} ${item.model}`

  const itemImages = item.images || []
  const hasImages = itemImages.length > 0
  const onlyOneImage = itemImages.length === 1

  return (
    <div className="deal-card">
      <div className="deal-card__carousel">
        <If
          condition={onlyOneImage}
          renderIf={
            <img
              src={itemImages[0]}
              className="deal-card__image"
              alt=""
              onClick={handleOpenMoreDetails}
            />
          }
          renderElse={
            <>
              {hasImages ? (
                <>
                  <DealSlider>
                    {itemImages.map((item) => {
                      return (
                        <img
                          src={item}
                          className="deal-card__image"
                          alt=""
                          key={item}
                        />
                      )
                    })}
                  </DealSlider>
                  <span
                    className="deal-card__carousel-gradient"
                    onClick={handleOpenMoreDetails}
                  />
                </>
              ) : (
                <span
                  className="deal-card__image"
                  onClick={handleOpenMoreDetails}
                >
                  <Img
                    file="default-car-image"
                    className="deal-card__image"
                    loading="lazy"
                  />
                </span>
              )}
            </>
          }
        />
      </div>
      <div className="deal-card__content">
        <div className="deal-card__title">
          <Text className="deal-card__model" size="md" weight="700">
            {formattedBrandModel}
          </Text>
          <Text className="deal-card__version" size="sm" weight="400">
            {item.versionDescription}
          </Text>
        </div>
        <div className="deal-card__prices">
          <Text className="deal-card__price" size="xl" weight="700">
            {formattedPrice}
          </Text>
          {/*
            // Pendente de validação, é possível que entre em breve no layout
            <Text className="deal-card__price-tag" size="sm" weight="400">
              abaixo da Fipe
            </Text>
          */}
        </div>
        <div className="deal-card__about">
          <div className="deal-card__about-seller">
            <Text className="deal-card__seller-name" size="sm">
              {item.sellerName}
            </Text>
            <Text className="deal-card__seller-city" size="sm" weight="400">
              {formattedCity}
            </Text>
          </div>
          <div className="deal-card__about-deal">
            <Text className="deal-card__deal-year" size="sm" weight="400">
              {formattedYears}
            </Text>
            <Text className="deal-card__deal-km" size="sm" weight="400">
              {formattedKm}
            </Text>
          </div>
        </div>
        <div className="deal-card__actions">
          <Button
            className="deal-card__more-details"
            variant="secondary"
            onClick={handleOpenMoreDetails}
          >
            + detalhes
          </Button>
          <Button className="deal-card__simulate" onClick={handleSimulate}>
            simular
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DealCard
