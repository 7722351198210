import { useEffect } from "react"

// Components
import Contacts from "components/Contacts"
import Footer from "components/Footer"
import Marketplace from "components/Marketplace"
import { BackButton } from "components/BackButton"
import { Text } from "@mobi/ds"
import { Likert } from "components/Likert"
import NextSteps from "components/NextSteps"
import Header from "components/Header"

// Hooks
import useFeedbackProposal from "./useFeedbackProposal"

// Style
import "./index.scss"

// Data
import { marketplaceCardItems, nextStepsItems } from "./dataContent"

// Assets
import CarIllustration from "images/car-check-illustration.svg"

// Utils
import { navigateWithQueryString } from "utils/browser"

const FeedbackProposal = () => {
  const { simulation, isClosedFlow, sellerStreetName } = useFeedbackProposal()
  const seller = simulation?.seller || {}
  const sellerName =
    `${simulation?.displayName
      ?.charAt(0)
      .toUpperCase()}${simulation?.displayName?.toLowerCase().slice(1)}` || ""
  const phone = seller?.contacts?.filter((item) => item.kind === "PHONE") || []
  const whatsapp = seller?.contacts?.find(
    (item) => item.kind === "WHATSAPP"
  )?.value

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header />
      <div className="feedback-proposal">
        <div className="feedback-proposal__container">
          {!isClosedFlow && (
            <BackButton onClick={() => navigateWithQueryString("/")} />
          )}
          <div className="feedback-proposal__illustration">
            <CarIllustration />
          </div>
          <div className="feedback-proposal__title-box">
            <Text className="simulation__title" as="h2" size="xxl" weight="800">
              simulação enviada!
            </Text>
            <Text size="md">
              Agora é com você! Entre em contato com a{" "}
              <strong>{sellerName}</strong> e adquira o seu carro financiado
              pelo <strong> Itaú </strong> ;)
            </Text>
          </div>
          <div className="feedback-proposal__content">
            <div className="feedback-proposal__wrapper">
              <Contacts
                baseClass="feedback-proposal"
                sellerName={sellerName}
                phone={phone}
                whatsapp={whatsapp}
                address={sellerStreetName}
                currentStep="feedback-proposal"
              />
            </div>
            <div className="feedback-proposal__wrapper">
              <NextSteps items={nextStepsItems} />
            </div>
          </div>
          <div className="feedback-proposal__likert-container">
            <Likert />
          </div>
          <div className="feedback-proposal__marketplace">
            <Marketplace
              cardItems={marketplaceCardItems}
              currentStep="feedback-proposal"
            />
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default FeedbackProposal
