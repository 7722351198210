//Components
import { VehiclesLikert } from "@garantidos/components"
import { Text } from "@mobi/ds"

// Services
import services from "services"

//Style
import "./index.scss"

export const Likert = () => {
	return (
		<VehiclesLikert
			likertTitleTag="div"
			likertTitle={
				<>
					<Text size="xl" weight={400}>
						avalie a experiência
					</Text>
					<Text size="md" weight={400} className="ds-likert__description">
						O que você achou dessa nova experiência na simulação
					</Text>
				</>
			}
			onSubmit={(payload) => services.simulation.sendFeedback(payload)}
		/>
	)
}
