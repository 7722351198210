import classnames from "classnames"

import "./index.scss"
import { useSwitch } from "./useSwitch"

const Switch = ({
  name,
  control,
  rules,
  disabled,
  className,
  onChange,
  ...props
}) => {
  const { isChecked, inputProps, handleOnClick } = useSwitch({
    name,
    control,
    rules,
    onChange,
  })

  return (
    <label
      htmlFor={name}
      className={classnames(className, "switch__container", {
        "switch__container--checked": isChecked,
        "switch__container--disabled": disabled,
      })}
    >
      <span className="switch__toggle-bullet" />

      <input
        id={name}
        type="checkbox"
        checked={isChecked}
        disabled={disabled}
        className="switch__hidden-input"
        onClick={handleOnClick}
        {...inputProps}
        {...props}
      />
    </label>
  )
}

export default Switch
