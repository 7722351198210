import { useState } from "react"

import { VehiclesIcon } from "@garantidos/components"

import { Slider } from "../Slider"
import Stepper from "../Stepper"
import "./DealSlider.scss"

export const DealSlider = ({ children }) => {
	const [activeSlideIndex, setActiveSlideIndex] = useState(1)

	const totalChildrens = children.length

	const stepperAriaLabel = `slide ${activeSlideIndex} de ${totalChildrens}`

	return (
		<div className="slider__container">
			<Slider
				{...{
					dots: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					prevArrow: (
						<button className="slider__arrow">
							<VehiclesIcon id="icon-arrow-left" />
						</button>
					),
					nextArrow: (
						<button className="slider__arrow">
							<VehiclesIcon id="icon-arrow-right" />
						</button>
					),
					afterChange: (current) => setActiveSlideIndex(current + 1)
				}}
			>
				{children}
			</Slider>
			<Stepper
				index={activeSlideIndex}
				max={totalChildrens}
				ariaLabel={stepperAriaLabel}
			/>
		</div>
	)
}
