import { useRef, useState } from "react"

//Utils
import { formatters } from "@garantidos/utils"

import * as debounce from "lodash.debounce"

const useFinancingSettings = ({
	stepData,
	formProps,
	requestNewSimulation,
	installments
}) => {
	const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState(false)
	const [resetCurrencyField, setResetCurrencyField] = useState(0)
	const [ariaLive, setAriaLive] = useState(false)
	const [currencyDescription, setCurrencyDescription] = useState(
		"esse valor é a nossa sugestão de entrada"
	)

	const {
		minAcceptableDownPayment,
		highChanceOfApprovalDownPayment: highChanceValue = false,
		vehicleValue
	} = stepData

	const maxAcceptable = formatters.calculatePercentage(vehicleValue, 90)
	const downPayment = formProps.watch("downPayment")
	const installmentsValue = formProps.watch("installments")
	const financeProtectionInsuranceValue = formProps.watch(
		"financeProtectionInsurance"
	)
	const financeInsuranceInstallmentValue = financeProtectionInsuranceValue
		? installments.find((i) => i.label === installmentsValue)
				?.financeInsuranceInstallmentValue
		: undefined

	const conditions = {
		isMinAcceptableDownPayment: downPayment === minAcceptableDownPayment,
		isLessThanMin: downPayment < minAcceptableDownPayment,
		hasHighChance: highChanceValue && downPayment >= highChanceValue,
		needAnalysis:
			highChanceValue &&
			downPayment < highChanceValue &&
			downPayment > minAcceptableDownPayment,
		isMaxDownPayment: downPayment === maxAcceptable,
		isBiggerThanMax: downPayment > maxAcceptable
	}

	const debouncedRequestNewSimulation = useRef(
		debounce(({ downPayment, isValidValue, downPaymentWasChanged }) => {
			if (isValidValue && downPaymentWasChanged) {
				requestNewSimulation(downPayment)
			}
		}, 1300)
	).current

	const getAcceptableDownPayment = (currentDownPaymentValue) => {
		const isLessThanMinAcceptable =
			currentDownPaymentValue < minAcceptableDownPayment
		const isMoreThanMaxAcceptable = currentDownPaymentValue > maxAcceptable

		if (isLessThanMinAcceptable) {
			return minAcceptableDownPayment
		}
		if (isMoreThanMaxAcceptable) {
			return maxAcceptable
		}

		return currentDownPaymentValue
	}

	return {
		setResetCurrencyField,
		setAriaLive,
		setCurrencyDescription,
		getAcceptableDownPayment,
		setIsInsuranceModalOpen,
		conditions,
		resetCurrencyField,
		downPayment,
		minAcceptableDownPayment,
		maxAcceptable,
		ariaLive,
		debouncedRequestNewSimulation,
		currencyDescription,
		isInsuranceModalOpen,
		installmentsValue,
		financeInsuranceInstallmentValue
	}
}

export default useFinancingSettings
