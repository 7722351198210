// Components
import SearchIcon from "images/icons/icon-search.svg"
import SheetSelectItem from "./components/SheetSelectItem"

// Hooks
import { useSheetSelectContent } from "./useSheetSelectContent"

// Styles
import "./index.scss"

export function SheetSelectContent({
  placeholder,
  name = "",
  options,
  fetchOptions,
  currentValue,
  onSelectItem,
  getIsChecked,
}) {
  const { onInputChange, filteredOptions } = useSheetSelectContent({
    options,
    fetchOptions,
  })

  const someOptionHasIcon = filteredOptions.some((option) => !!option.icon)

  return (
    <div className="sheet-select__content">
      <div className="sheet-select__search">
        <SearchIcon className="sheet-select__search-icon" />
        <input onChange={onInputChange} placeholder={placeholder} />
      </div>

      <fieldset className="sheet-select__list">
        {filteredOptions.map((option, index) => {
          const hasGroupTitle =
            option.group &&
            (index === 0 || filteredOptions[index - 1].group !== option.group)

          return (
            <SheetSelectItem
              key={option.value}
              option={option}
              hasGroupTitle={hasGroupTitle}
              someOptionHasIcon={someOptionHasIcon}
              currentValue={currentValue}
              onSelectItem={onSelectItem}
              getIsChecked={getIsChecked}
              name={name}
            />
          )
        })}
      </fieldset>
    </div>
  )
}
