//Components
import { Input, Select } from "@mobi/ds"
import Sender from "components/Sender"
import { SheetSelect } from "components/SheetSelect"
import { PurchasePeriod } from "../../../components/PurchasePeriod"

//Hooks
import useVehicleDealPropertiesForm, {
  finalYearSelectDefaultValue,
  initialYearSelectDefaultValue,
} from "./useVehicleDealPropertiesForm"

// Utils
import { saveOnSessionStorage, getFromSessionStorage } from "utils/storage"

//Style
import "./index.scss"

//Icon
import SearchIcon from "images/icons/icon-search.svg"
import IconModel from "images/icons/icon-model.svg"

const PRICES_MIN_VALUE = 5000

const VehicleDealPropertiesForm = ({
  setIsLoading,
  setIsSubmitting,
  updateLocalStep,
  onCancel,
}) => {
  const {
    onSearchCities,
    vehiclesYears,
    formProps,
    simulation,
    setSimulation,
    onSubmit,
    brand,
    brands,
    models,
  } = useVehicleDealPropertiesForm({
    setIsLoading,
    setIsSubmitting,
    updateLocalStep,
  })

  const model = formProps.watch("model")

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = formProps

  return (
    <form className="step__form step__form--vehicle-deal-properties">
      <Input.Currency
        name="initialPrice"
        label="valor mínimo"
        autoComplete="on"
        control={control}
        defaultValue={simulation?.vehicleDealProperties?.initialPrice}
        shouldResetOn={simulation?.vehicleDealProperties?.initialPrice}
        rules={{
          min: PRICES_MIN_VALUE,
          max: 1200000,
        }}
        validators={{
          rules: {
            validate: {
              min: (value) => !value || value >= PRICES_MIN_VALUE,
            },
          },
        }}
        onChange={(value) => {
          saveOnSessionStorage({
            name: "initialPrice",
            value: value,
          })
        }}
        onBlur={(initialPrice) => {
          const finalPrice = formProps.getValues("finalPrice")

          if (initialPrice && finalPrice && initialPrice > finalPrice) {
            setSimulation({
              ...simulation,
              ...{
                vehicleDealProperties: {
                  ...simulation.vehicleDealProperties,
                  initialPrice: finalPrice,
                },
              },
            })

            formProps.setValue("initialPrice", finalPrice, {
              shouldValidate: true,
            })

            saveOnSessionStorage({
              name: "initialPrice",
              value: finalPrice,
            })
          }
        }}
        required={false}
      />
      <Input.Currency
        name="finalPrice"
        label="valor máximo"
        autoComplete="on"
        defaultValue={simulation?.vehicleDealProperties?.finalPrice}
        shouldResetOn={simulation?.vehicleDealProperties?.finalPrice}
        required={true}
        rules={{
          min: PRICES_MIN_VALUE,
          max: 1200000,
        }}
        onChange={(value) => {
          saveOnSessionStorage({
            name: "finalPrice",
            value: value,
          })
        }}
        onBlur={(finalPrice) => {
          const initialPrice = formProps.getValues("initialPrice")

          if (initialPrice && finalPrice && initialPrice > finalPrice) {
            setSimulation({
              ...simulation,
              ...{
                vehicleDealProperties: {
                  ...simulation.vehicleDealProperties,
                  finalPrice: initialPrice,
                },
              },
            })

            formProps.setValue("finalPrice", initialPrice, {
              shouldValidate: true,
            })

            saveOnSessionStorage({
              name: "finalPrice",
              value: initialPrice,
            })
          } else if (!finalPrice) {
            formProps.setValue("finalPrice", "", {
              shouldValidate: true,
            })
          }
        }}
        control={control}
      />
      <div className="step__vehicle-year-row">
        <div className="step__vehicle-year-col">
          <Select
            className="step__year step__year--vehicle-deal-properties"
            name="initialYear"
            label=""
            description="ex. 2001"
            minSearch={2}
            options={vehiclesYears}
            validators={{
              rules: {
                validate: {
                  required: (value) =>
                    !!value && value !== initialYearSelectDefaultValue,
                },
              },
            }}
            onBlur={() => {
              const initialYear = formProps.getValues("initialYear")
              const initialYearSession = getFromSessionStorage({
                name: "initialYear",
              })

              if (!initialYear) {
                const initialYearDefault =
                  initialYearSession || new Date().getFullYear() - 1

                formProps.setValue("initialYear", initialYearDefault, {
                  shouldValidate: true,
                })
              }
            }}
            onSelectItem={({ value }) => {
              saveOnSessionStorage({
                name: "initialYear",
                value,
              })
            }}
            required={true}
            control={control}
            formProps={formProps}
          />
        </div>
        <div className="step__vehicle-year-col">
          <Select
            className="step__year step__year--vehicle-deal-properties"
            name="finalYear"
            label=""
            description="ex. 2002"
            minSearch={2}
            options={vehiclesYears}
            validators={{
              rules: {
                validate: {
                  required: (value) =>
                    !!value && value !== finalYearSelectDefaultValue,
                },
              },
            }}
            onBlur={() => {
              const finalYear = formProps.getValues("finalYear")
              const finalYearSession = getFromSessionStorage({
                name: "finalYear",
              })

              if (!finalYear) {
                const finalYearDefault =
                  finalYearSession || new Date().getFullYear()
                formProps.setValue("finalYear", finalYearDefault, {
                  shouldValidate: true,
                })
              }
            }}
            control={control}
            formProps={formProps}
            onSelectItem={({ value }) => {
              saveOnSessionStorage({
                name: "finalYear",
                value,
              })
            }}
          />
        </div>
      </div>

      <SheetSelect.Field
        label="marca"
        description="escolha uma marca"
        placeholder="digite uma marca"
        className="select-brand"
        name="brand"
        control={formProps.control}
        options={brands}
        required={true}
        onChange={(newBrandValue) => {
          formProps.setValue("brand", newBrandValue, {
            shouldValidate: true,
          })
          formProps.setValue("model", "")

          saveOnSessionStorage({
            name: "brand",
            value: newBrandValue,
          })
        }}
        getIcon={(option) => option?.icon}
      />

      <SheetSelect.Field
        label="modelo"
        description="escolha um modelo"
        placeholder="digite um modelo"
        className="select-model"
        name="model"
        control={formProps.control}
        options={models}
        required={false}
        disabled={!brand}
        onChange={(newModelValue) => {
          formProps.setValue("model", newModelValue, {
            shouldValidate: true,
          })

          saveOnSessionStorage({
            name: "model",
            value: newModelValue,
          })
        }}
        getIcon={(option) => (option ? <IconModel /> : undefined)}
      />

      <Select
        className="step__select-city"
        name="dealCity"
        minSearchCharacters={2}
        isSearchingMessage="buscando..."
        label="pesquisar em qual cidade?"
        description="campo opcional"
        required={false}
        fetchOptions={onSearchCities}
        onSelectItem={({ value, state }) => {
          formProps.setValue("dealCity", value, {
            shouldValidate: true,
          })
          formProps.setValue("dealState", state, {
            shouldValidate: true,
          })
        }}
        onBlur={() => {
          const currentDealCity = formProps.getValues("dealCity")

          if (!currentDealCity) {
            formProps.setValue("dealState", "", {
              shouldValidate: true,
            })
          }
        }}
        getInputValue={(fieldValue) => {
          const state = formProps.getValues("dealState")

          if (!state) {
            return fieldValue
          }

          return `${fieldValue}, ${state}`
        }}
        control={control}
        formProps={formProps}
        icon={<SearchIcon />}
      />
      <PurchasePeriod control={control} />
      <Sender
        isNextDisabled={!isValid}
        onNext={handleSubmit(onSubmit)}
        nextLabel="avançar"
        hasPrev={false}
        onPrev={onCancel}
        prevLabel="voltar"
      />
      <br />
      <br />
    </form>
  )
}

export default VehicleDealPropertiesForm
