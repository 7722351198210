import { useEffect, useState } from "react"
import classnames from "classnames"

// Components
import ArrowRightIcon from "images/icons/icon-arrow-right.svg"

// Styles
import "./index.scss"

export function SheetSelectTrigger({
  icon,
  label,
  value,
  disabled,
  className,
  ...rest
}) {
  const isIconString = !!icon && typeof icon === "string"
  const [isIconStringValid, setIsIconStringValid] = useState(isIconString)

  useEffect(() => {
    const isNewIconString = !!icon && typeof icon === "string"
    setIsIconStringValid(isNewIconString)
  }, [icon])

  return (
    <button
      type="button"
      className={classnames("sheet-select__trigger", className, {
        "sheet-select__trigger--disabled": disabled,
      })}
      disabled={disabled}
      {...rest}
    >
      <div>
        {isIconStringValid && (
          <img
            className="sheet-select__trigger-icon"
            src={icon}
            onError={() => setIsIconStringValid(false)}
          />
        )}
        {icon && !isIconStringValid && (
          <span aria-hidden="true" className="sheet-select__trigger-icon">
            {icon}
          </span>
        )}
        <div className="sheet-select__trigger-label">{label}</div>
      </div>
      <div>
        {value && <div className="sheet-select__trigger-value">{value}</div>}
        <ArrowRightIcon className="sheet-select__trigger-arrow" />
      </div>
    </button>
  )
}
