// Components
import { VehiclesIcon } from "@garantidos/components"

import If from "@mobi/components/If"
import { Button, Text } from "@mobi/ds"
// Tracking
import analytics from "@mobi/libraries/analytics"
//Utils
import phoneMask from "@mobi/utils/formatters/phone"

//Style
import "./index.scss"

const Contacts = ({
	baseClass,
	sellerName,
	phone = [],
	whatsapp,
	address,
	currentStep
}) => {
	return (
		<div className={`${baseClass}__contacts contacts`}>
			<Text
				className={`${baseClass}__seller-name contacts__seller-name`}
				as="h1"
				weight="700"
			>
				{sellerName}
			</Text>

			<If
				condition={address}
				renderIf={
					<Text
						className={`${baseClass}__address contacts__address`}
						as="p"
						size="md"
						weight="400"
					>
						{address}
					</Text>
				}
			/>

			<If
				condition={Object.keys(phone).length > 0}
				renderIf={
					<>
						<div className={`${baseClass}__phone contacts__phone`}>
							{phone.map((item) => {
								return (
									<div key={item.value}>
										<Text
											className={`${baseClass}__phone-number contacts__phone-number`}
											as="p"
											size="md"
											weight="400"
										>
											{`Tel. ${phoneMask(item?.value.trim() || "")}`}
										</Text>
									</div>
								)
							})}
						</div>
					</>
				}
			/>

			{whatsapp && (
				<Text
					className={`${baseClass}__whatsapp-number contacts__whatsapp-number`}
					as="p"
					size="md"
					weight="400"
				>
					{`Cel. ${phoneMask(whatsapp)}`}
				</Text>
			)}

			<div className={`${baseClass}__buttons contacts__buttons`}>
				<If
					condition={whatsapp}
					renderIf={
						<Button
							tagName="a"
							target="_blank"
							rightIcon={<VehiclesIcon id="icon-whatsapp-filled" />}
							href={`https://wa.me/55${whatsapp}`}
							onClick={() => {
								const webElement = {
									buttonName: "whatsappLojista",
									buttonText: "whatsapp do lojista",
									location: "Simulation"
								}
								analytics.track("buttonClickSegment", webElement)
							}}
						>
							whatsapp do lojista
						</Button>
					}
				/>

				<If
					condition={phone.length > 0}
					renderIf={
						<Button
							tagName="a"
							variant="secondary"
							rightIcon={<VehiclesIcon id="icon-telephone-outlined" />}
							href={`tel:${phone[0]?.value}`}
							onClick={() => {
								const webElement = {
									buttonName: "ligarLojista",
									buttonText: "Ligar para o lojista",
									location: "Simulation"
								}
								analytics.track("buttonClickSegment", webElement)
							}}
						>
							ligar para o lojista
						</Button>
					}
				/>
			</div>
		</div>
	)
}

export default Contacts
