import { Fragment, useState } from "react"

// Components
import { Text } from "@mobi/ds"

// Styles
import "./index.scss"

export default function SheetSelectItem({
  option,
  hasGroupTitle,
  someOptionHasIcon = false,
  name,
  currentValue,
  onSelectItem,
  getIsChecked,
}) {
  const isChecked = !!getIsChecked
    ? getIsChecked(option)
    : option.value === currentValue

  const [hasIcon, setHasIcon] = useState(!!option.icon)

  return (
    <Fragment>
      {hasGroupTitle && (
        <Text size="md" weight="700" className="sheet-select__group">
          {option.group}
        </Text>
      )}
      <label htmlFor={`${name}-${option.label}`} className="sheet-select__item">
        <div className="sheet-select__item-wrapper">
          {hasIcon && (
            <img
              className="sheet-select__item-icon"
              src={option.icon}
              onError={() => setHasIcon(false)}
            />
          )}
          {!hasIcon && someOptionHasIcon && (
            <span className="sheet-select__item-icon" />
          )}
          <div className="sheet-select__item-content">
            <Text size="md" weight="400" className="sheet-select__item-label">
              {option.label}
            </Text>
            {option.description && (
              <Text
                size="sm"
                weight="400"
                className="sheet-select__item-description"
              >
                {option.description}
              </Text>
            )}
          </div>
        </div>

        <input
          type="radio"
          id={`${name}-${option.label}`}
          className="sheet-select__item-radio"
          value={option.value}
          checked={isChecked}
          onClick={() => onSelectItem(isChecked ? "" : option)}
          onChange={() => {}}
        />
      </label>
    </Fragment>
  )
}
