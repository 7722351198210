// Components
import Modal from "components/Modal"
import { Button } from "@mobi/ds"
import ArrowLeftIcon from "images/icons/icon-arrow-left.svg"
import CloseIcon from "images/icons/icon-close.svg"

// Styles
import "./index.scss"

export function SheetSelectModal({
  isOpen,
  onClose,
  label,
  description,
  isSubmitDisabled,
  children,
}) {
  return (
    <Modal
      isOpened={isOpen}
      onCloseClick={onClose}
      variant="drawer"
      className="sheet-select__modal"
      hasCloseButton={false}
    >
      <div className="sheet-select__modal-body">
        <header className="sheet-select__modal-header">
          <button
            type="button"
            className="sheet-select__modal-back"
            onClick={onClose}
          >
            <ArrowLeftIcon className="sheet-select__modal-back-icon" />
            voltar
          </button>

          <div className="sheet-select__modal-label">{label}</div>
          <div className="sheet-select__modal-title">{description}</div>

          <button
            type="button"
            className="sheet-select__modal-close"
            onClick={onClose}
          >
            <CloseIcon className="sheet-select__modal-close-icon" />
          </button>
        </header>

        {children}
      </div>

      <footer className="sheet-select__modal-footer">
        <Button
          type="button"
          className="sheet-select__modal-submit"
          disabled={isSubmitDisabled}
          onClick={onClose}
        >
          salvar alterações
        </Button>
      </footer>
    </Modal>
  )
}
