export const desktopFiltersOptions = [
  { key: "sort", title: "ordernar por", icon: "icon-sort" },
  { key: "brand", title: "marca" },
  { key: "model", title: "modelo" },
  { key: "years", title: "ano" },
  { key: "kilometers", title: "quilometragem" },
  { key: "plateFinal", title: "final de placa" },
  { key: "vehicleCondition", title: "condição do veículo" },
  { key: "city", title: "localização" },
  { key: "cityRange", title: "raio de busca" },
  { key: "prices", title: "preço" },
]
