import { useEffect, useRef, useState } from "react"
import Fuse from "fuse.js"
import debounce from "lodash.debounce"

export function useSheetSelectContent({ options, fetchOptions }) {
  const [filteredOptions, setFilteredOptions] = useState(options || [])

  const debouncedSearch = useRef(
    debounce(async (inputValue) => {
      const newFilteredOptions = await fetchOptions(inputValue)
      setFilteredOptions(newFilteredOptions)
    }, 500)
  ).current

  const filterOptions = (inputValue) => {
    if (!inputValue) {
      setFilteredOptions(options || [])
      return
    }

    const fuse = new Fuse(options || [], {
      keys: ["label"],
    })

    const newFilteredOptions = fuse.search(inputValue)
    setFilteredOptions(newFilteredOptions.map(({ item }) => item))
  }

  const onInputChange = (event) => {
    const inputValue = event?.target?.value || ""

    if (options && options.length > 0) {
      filterOptions(inputValue)
    } else if (fetchOptions) {
      debouncedSearch(inputValue)
    }
  }

  useEffect(() => {
    onInputChange()
  }, [options])

  return { onInputChange, filteredOptions }
}
