import { useEffect, useState } from "react"
import classnames from "classnames"

// Components
import { BrandContent } from "../BrandContent"
import { CityContent } from "../CityContent"
import { CityRangeContent } from "../CityRangeContent"
import { PlateFinalContent } from "../PlateFinalContent"
import { KilometersContent } from "../KilometersContent"
import { ModelContent } from "../ModelContent"
import { PricesContent } from "../PricesContent"
import { SortContent } from "../SortContent"
import { VehicleConditionContent } from "../VehicleConditionContent"
import { YearsContent } from "../YearsContent"

// Services
import services from "services"

// Utils
import isMobile from "@mobi/utils/browser/isMobile"
import { getUUIDFromUrl } from "utils/browser"

import "./index.scss"
import Modal from "components/Modal"

export function FiltersModal({
  openedFilter,
  setOpenedFilter,
  onSubmitFilter,
  getValues,
  watch,
  onClose,
  total,
}) {
  const [vehiclesYears, setVehiclesYears] = useState([])
  const [vehiclesBrands, setVehiclesBrands] = useState([])
  const [vehiclesModels, setVehiclesModels] = useState([])

  const brand = watch("brand")

  useEffect(() => {
    async function getYears() {
      const uuid = getUUIDFromUrl()
      if (!uuid) return Promise.reject()
      const response = await services.vehicles.getYears({
        [uuid.name]: uuid.value,
      })
      const years = response
      const filteredYears = years.filter(
        (item, index, acc) =>
          acc.findIndex((t) => t.value === item.value) === index
      )
      setVehiclesYears(filteredYears)
    }

    async function getBrands() {
      const vehiclesBrandsResponse = await services.vehicles.getBrands()
      setVehiclesBrands(vehiclesBrandsResponse)
    }

    getYears()
    getBrands()
  }, [])

  useEffect(() => {
    async function getModels() {
      if (brand) {
        const vehiclesModelsResponse = await services.vehicles.getModels({
          brand: brand.value,
        })

        setVehiclesModels(vehiclesModelsResponse)
      } else {
        setVehiclesModels([])
      }
    }

    getModels()
  }, [brand])

  const filtersContents = {
    brand: () => (
      <BrandContent
        onSubmitFilter={onSubmitFilter}
        getValues={getValues}
        onClose={onClose}
        options={vehiclesBrands}
      />
    ),
    model: () => (
      <ModelContent
        onSubmitFilter={onSubmitFilter}
        getValues={getValues}
        onClose={onClose}
        options={vehiclesModels}
      />
    ),
    years: () => (
      <YearsContent
        onSubmitFilter={onSubmitFilter}
        getValues={getValues}
        onClose={onClose}
        options={vehiclesYears}
      />
    ),
    kilometers: () => (
      <KilometersContent
        onSubmitFilter={onSubmitFilter}
        getValues={getValues}
        onClose={onClose}
      />
    ),
    plateFinal: () => (
      <PlateFinalContent
        onSubmitFilter={onSubmitFilter}
        getValues={getValues}
        onClose={onClose}
      />
    ),
    vehicleCondition: () => (
      <VehicleConditionContent
        onSubmitFilter={onSubmitFilter}
        getValues={getValues}
        onClose={onClose}
      />
    ),
    city: () => (
      <CityContent
        onSubmitFilter={onSubmitFilter}
        getValues={getValues}
        onClose={onClose}
        total={total}
      />
    ),
    cityRange: () => (
      <CityRangeContent
        onSubmitFilter={onSubmitFilter}
        getValues={getValues}
        onClose={onClose}
        watch={watch}
        setOpenedFilter={setOpenedFilter}
      />
    ),
    prices: () => (
      <PricesContent
        onSubmitFilter={onSubmitFilter}
        getValues={getValues}
        onClose={onClose}
      />
    ),
    sort: () => (
      <SortContent
        onSubmitFilter={onSubmitFilter}
        getValues={getValues}
        onClose={onClose}
      />
    ),
  }

  if (isMobile) {
    return (
      <Modal
        isOpened={!!openedFilter}
        onCloseClick={onClose}
        variant="drawer"
        className="mobile-filters__modal"
      >
        {openedFilter && filtersContents[openedFilter]()}
      </Modal>
    )
  }

  return (
    <div
      className={classnames("filters-drawer", {
        "filters-drawer--opened": !!openedFilter,
      })}
    >
      {openedFilter && filtersContents[openedFilter]()}
    </div>
  )
}
