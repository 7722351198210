import { VehiclesIcon } from "@garantidos/components"

//Components
import { Text } from "@mobi/ds"

// Styles
import "./index.scss"
import classnames from "classnames"

const Pagination = ({ pages, currentPage, onNext, onPrev, onPageChange }) => {
	const percentage = ((currentPage + 1) / pages) * 100

	return (
		<div className="pagination">
			<button
				className={classnames("pagination__arrow pagination__arrow-left", {
					"pagination__arrow--disabled": currentPage === 0
				})}
				aria-label="lista de lojas, página anterior"
				tabIndex={currentPage === 0 ? "-1" : "0"}
				aria-hidden={currentPage === 0 ? "true" : "false"}
				onClick={(e) => {
					onPrev(e)
					onPageChange && onPageChange()
				}}
			>
				<VehiclesIcon
					id="icon-arrow-left"
					className="pagination__arrow-icon"
					width="10"
					height="18"
				/>
			</button>

			<Text
				as="p"
				size="xs"
				weight={700}
				className="pagination__label"
				aria-hidden="true"
			>
				{currentPage + 1}
			</Text>

			<div className="pagination__progress" aria-hidden>
				<div
					className="pagination__bar"
					style={{ width: `${percentage}%` }}
				></div>
			</div>

			<Text
				as="p"
				size="xs"
				weight={700}
				className="pagination__label"
				aria-hidden="true"
			>
				{pages}
			</Text>

			<button
				className={classnames("pagination__arrow pagination__arrow-right", {
					"pagination__arrow--disabled": currentPage + 1 === pages
				})}
				aria-label="lista de lojas, próxima página"
				tabIndex={currentPage + 1 === pages ? "-1" : "0"}
				aria-hidden={currentPage + 1 === pages ? "true" : "false"}
				onClick={(e) => {
					onNext(e)
					onPageChange && onPageChange()
				}}
			>
				<VehiclesIcon
					id="icon-arrow-right"
					className="pagination__arrow-icon"
					width="10"
					height="18"
				/>
			</button>
		</div>
	)
}

export default Pagination
