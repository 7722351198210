import { useEffect, useState } from "react"

import { formatters } from "@garantidos/utils"

//Hooks
import useTimeStamp from "./useTimestamp"
// Utils
import { saveOnSessionStorage } from "utils/storage"

const useTimer = () => {
	const { isPersistedTimestampValid, getExpirationTimestamp } = useTimeStamp()
	const [expirationTimestamp, setExpirationTimestamp] = useState(
		getExpirationTimestamp()
	)
	const [isRunning, setIsRunning] = useState(true)
	const [timeCounter, setTimeCounter] = useState(() => {
		const newExpirationTimestamp = getExpirationTimestamp()
		const timer = Date.parse(newExpirationTimestamp) - Date.now()
		return {
			minutes: formatters.formattedMillisecondsToMinutes(timer),
			seconds: formatters.formattedMillisecondsToSeconds(timer)
		}
	})

	const keyToPersistExpirationTime = "lastCodeSentTimestamp"
	const totalSecondsCounter = 120

	const getTimeCounter = () => {
		return Date.parse(expirationTimestamp) - Date.now()
	}

	useEffect(() => {
		if (!isPersistedTimestampValid()) {
			saveOnSessionStorage({
				name: keyToPersistExpirationTime,
				value: Date.now()
			})
		}

		const newExpirationTimestamp = getExpirationTimestamp()
		setExpirationTimestamp(newExpirationTimestamp)
	}, [keyToPersistExpirationTime])

	useEffect(() => {
		const interval = setInterval(() => {
			setIsRunning(true)
			const timer = getTimeCounter()
			setTimeCounter({
				minutes: formatters.formattedMillisecondsToMinutes(timer),
				seconds: formatters.formattedMillisecondsToSeconds(timer)
			})

			if (timer < 0) setIsRunning(false)
		}, 1000)

		return () => clearInterval(interval)
	}, [expirationTimestamp])

	const handleRestartTimeCounter = () => {
		const newTime = new Date()
		newTime.setSeconds(newTime.getSeconds() + totalSecondsCounter)

		saveOnSessionStorage({
			name: keyToPersistExpirationTime,
			value: Date.now()
		})

		setExpirationTimestamp(newTime)
		setIsRunning(true)
	}

	return {
		handleRestartTimeCounter,
		isRunning,
		timeCounter
	}
}

export default useTimer
