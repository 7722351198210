//Components
import { Text } from "@mobi/ds"
import If from "@mobi/components/If"
import Loader from "components/Loader"
import Stepper from "components/Stepper"
import VehicleDealPropertiesForm from "./components/VehicleDealPropertiesForm"
import { StepAsideImage } from "../components/StepAsideImage"
import { BackButton } from "components/BackButton"
import Header from "components/Header"

//Hooks
import useVehicleDealProperties from "./useVehicleDealProperties"

// Utils
import isMobile from "@mobi/utils/browser/isMobile"
import { navigateWithQueryString } from "utils/browser"

const VehicleDealProperties = ({ current }) => {
  const { title } = current

  const {
    isLoading,
    setIsLoading,
    updateLocalStep,
    onPrev,
    isSubmitting,
    setIsSubmitting,
  } = useVehicleDealProperties()

  return (
    <>
      <If condition={!isMobile} renderIf={<Header />} />
      <If
        condition={isLoading || isSubmitting}
        renderIf={<Loader variant="spinner-only" />}
      />
      <div className="step step--vehicle-deal-properties">
        <div className="step__container step__container--vehicle-deal-properties">
          <StepAsideImage classesSufix="vehicle-deal-properties" />

          <div className="step__aside-content step__aside-content--vehicle-deal-properties">
            <div className="step__content step__content--vehicle-deal-properties">
              <br />
              <BackButton onClick={() => navigateWithQueryString("/")} />
              <Stepper index={1} max={3} />
              <Text
                className="step__title step__title--vehicle-deal-properties"
                as="h1"
                size="xxl"
              >
                {title}
              </Text>
              <Text size="md">
                qual o carro você quer simular um financiamento?
              </Text>

              <VehicleDealPropertiesForm
                setIsLoading={setIsLoading}
                setIsSubmitting={setIsSubmitting}
                updateLocalStep={updateLocalStep}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default VehicleDealProperties
