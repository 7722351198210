import isClient from "@mobi/utils/browser/isClient"

export const ga = (eventName, params) => {
  // eslint-disable-next-line no-undef
  gtag("event", eventName, {
    ...params,
  })
}

export const gtm = (params) => {
  if (isClient) {
    const dataLayer = window.dataLayer || []
    dataLayer.push(params)
  }
}
