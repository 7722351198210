// Utils
import { formatters } from "@garantidos/utils"

import { cityFilterClearValues } from "../CityContent"
import { cityRangeFilterClearValues } from "../CityRangeContent"
// Data
import { cityKmRangeOptions } from "../CityRangeContent/dataContent"
import { kilometersFilterClearValues } from "../KilometersContent"
// Contents
import { modelFilterClearValues } from "../ModelContent"
import { plateFinalFilterClearValues } from "../PlateFinalContent"
import { vehicleConditionFilterClearValues } from "../VehicleConditionContent"

export const getFiltersButtons = ({ getValues }) => [
	{
		label: "marca",
		getFilterValue: () => getValues("brand")?.label,
		filterKey: "brand",
		isClearable: false
	},
	{
		label: "modelo",
		getFilterValue: () => getValues("model")?.label,
		filterKey: "model",
		defaultValue: modelFilterClearValues
	},
	{
		label: "ano",
		getFilterValue: () =>
			`${getValues("initialYear")} a ${getValues("finalYear")}`,
		filterKey: "years",
		isClearable: false
	},
	{
		label: "quilometragem",
		getFilterValue: () => {
			const minKm = getValues("minKm")
			const maxKm = getValues("maxKm")

			const formattedMinKm = formatters.numberToStringWithDots(minKm)
			const formattedMaxKm = formatters.numberToStringWithDots(maxKm)

			if (!minKm && !maxKm) {
				return ""
			}

			if (!minKm && maxKm) {
				return `até ${formattedMaxKm} km`
			}

			if (!maxKm) {
				return `${formattedMinKm} km ou mais`
			}

			return `${formattedMinKm} km até ${formattedMaxKm} km`
		},
		filterKey: "kilometers",
		defaultValue: kilometersFilterClearValues
	},
	{
		label: "final de placa",
		getFilterValue: () => {
			const plateFinal = getValues("plateFinal")

			if (!plateFinal) {
				return ""
			}

			const splittedPlateFinals = plateFinal.split("|")
			const formattedPlateFinals = splittedPlateFinals.reduce(
				(acc, current, index) =>
					`${acc}${
						index === splittedPlateFinals.length - 1
							? " e "
							: index === 0
							? ""
							: ", "
					}${current}`,
				""
			)
			return formattedPlateFinals
		},
		filterKey: "plateFinal",
		defaultValue: plateFinalFilterClearValues
	},
	{
		label: "condição do veículo",
		getFilterValue: () => (getValues("isNewVehicle") ? "0km" : "qualquer"),
		getIsDirty: () => !!getValues("isNewVehicle"),
		filterKey: "vehicleCondition",
		defaultValue: vehicleConditionFilterClearValues
	},
	{
		label: "localização",
		getFilterValue: () => {
			const city = getValues("city")

			if (!city) {
				return ""
			}

			return `${city}, ${getValues("state")}`
		},
		filterKey: "city",
		defaultValue: cityFilterClearValues
	},
	{
		label: "raio de busca",
		getFilterValue: () => {
			const kmRange = getValues("kmRange")

			if (!kmRange) {
				return ""
			}

			return cityKmRangeOptions.find(({ value }) => value === kmRange)?.label
		},
		getIsDirty: (filterValue) =>
			cityKmRangeOptions && filterValue !== cityKmRangeOptions[0].label,
		filterKey: "cityRange",
		defaultValue: cityRangeFilterClearValues
	},
	{
		label: "preço",
		getFilterValue: () => {
			const initialPrice = getValues("initialPrice")
			const finalPrice = getValues("finalPrice")

			const formattedInitialPrice =
				formatters.numberToStringWithDots(initialPrice)
			const formattedFinalPrice = formatters.numberToStringWithDots(finalPrice)

			if (!initialPrice && !finalPrice) {
				return ""
			}

			if (!initialPrice && finalPrice) {
				return `até R$ ${formattedFinalPrice}`
			}

			if (!finalPrice) {
				return `R$ ${formattedInitialPrice} ou mais`
			}

			return `R$ ${formattedInitialPrice} até R$ ${formattedFinalPrice}`
		},
		filterKey: "prices",
		isClearable: false
	}
]
