// Components
import { VehiclesIcon } from "@garantidos/components"

// Styles
import "./index.scss"

export function BackButton({ onClick, text = "voltar ao início", ...rest }) {
	return (
		<button type="button" className="back-button" onClick={onClick} {...rest}>
			<VehiclesIcon id="icon-arrow-left" className="back-button__icon" /> {text}
		</button>
	)
}
