//Components
import { Text } from "@mobi/ds"

export function QuantityFound({ total, watch }) {
  const getQuantityFoundText = () => {
    if (total === 0 || !total) {
      return "nenhuma oferta encontrada para"
    }

    if (total === 1) {
      return "1 oferta encontrada para"
    }

    return `${total} ofertas encontradas para`
  }

  const [brand, model, city, state, kmRange] = watch([
    "brand",
    "model",
    "city",
    "state",
    "kmRange",
  ])
  const kmRangeText =
    kmRange && kmRange !== "city-only" ? `até ${kmRange}km da cidade de ` : ""
  const brandModelLocationText = `${brand?.label}${
    model ? ` ${model.label}` : ""
  }${city ? ` em ${kmRangeText}${city}, ${state}` : ""}`

  return (
    <div>
      <Text size="md" weight="400">
        {getQuantityFoundText()}
      </Text>
      <Text size="lg" weight="700">
        {brandModelLocationText}
      </Text>
    </div>
  )
}
