//Components
import { Text } from "@mobi/ds"

//Style
import "./Stepper.scss"

const Stepper = ({ index, max, ariaLabel }) => {
  const percentage = (index / max) * 100

  return (
    <div
      className="stepper__content"
      role="text"
      aria-label={ariaLabel ?? `passo ${index} de ${max}`}
    >
      <Text
        as="p"
        size="sm"
        weight={700}
        className="stepper__label"
        aria-hidden="true"
      >
        {index}
      </Text>

      <div className="stepper__progress" aria-hidden>
        <div className="stepper__bar" style={{ width: `${percentage}%` }}></div>
      </div>

      <Text
        as="p"
        size="sm"
        weight={700}
        className="stepper__label"
        aria-hidden="true"
      >
        {max}
      </Text>
    </div>
  )
}

export default Stepper
