// Components
import { useForm } from "react-hook-form"

import { VehiclesIcon } from "@garantidos/components"

import { Button, Radio, Text } from "@mobi/ds"
// Utils
import isMobile from "@mobi/utils/browser/isMobile"

// Data
import { cityKmRangeOptions } from "./dataContent"
// Styles
import "./index.scss"

export const cityRangeFilterClearValues = {
	kmRange: "50"
}

export function CityRangeContent({
	getValues,
	watch,
	onSubmitFilter,
	onClose,
	setOpenedFilter
}) {
	const city = watch("city")

	const { control, handleSubmit } = useForm({
		defaultValues: { kmRange: getValues("kmRange") }
	})

	const onClear = () => {
		onSubmitFilter(cityRangeFilterClearValues)
		onClose()
	}

	const onSubmit = handleSubmit(async (data) => {
		onSubmitFilter(data)
		onClose()
	})

	const handleOpenCityFilter = () => {
		setOpenedFilter("city")
	}

	return (
		<>
			<div className="filters-drawer__content">
				<Text size="lg" weight="400" className="filters-drawer__title">
					{isMobile ? "selecione o raio de busca" : "raio de busca"}
				</Text>
				{city ? (
					<fieldset>
						{cityKmRangeOptions.map(({ label, value }) => (
							<Radio
								key={value}
								id={value}
								value={value}
								name="kmRange"
								label={label}
								required={false}
								control={control}
							/>
						))}
					</fieldset>
				) : (
					<div className="range-unavailable">
						<VehiclesIcon
							id="icon-outlined-warning"
							className="range-unavailable__icon"
						/>
						<Text size="md" weight="400" className="range-unavailable__title">
							raio indisponível para busca sem cidade selecionada
						</Text>
						<Text
							size="md"
							weight="400"
							className="range-unavailable__description"
						>
							Busque e selecione uma cidade e estado para poder definir o raio
							de busca de sua pesquisa.
						</Text>
					</div>
				)}
			</div>
			<footer className="filters-drawer__footer">
				{city ? (
					<>
						<Button variant="secondary" onClick={onClear}>
							limpar
						</Button>
						<Button onClick={onSubmit}>filtrar</Button>
					</>
				) : (
					<Button
						rightIcon={<VehiclesIcon id="icon-arrow-right" />}
						className="range-unavailable__button"
						onClick={handleOpenCityFilter}
					>
						definir localização
					</Button>
				)}
			</footer>
		</>
	)
}
