import { useForm } from "react-hook-form"

// Components
import { Button, Text, Select } from "@mobi/ds"

// Utils
import { saveOnSessionStorage, getFromSessionStorage } from "utils/storage"
import {
  finalYearSelectDefaultValue,
  initialYearSelectDefaultValue,
} from "pages/Form/steps/VehicleDealProperties/components/VehicleDealPropertiesForm/useVehicleDealPropertiesForm"

export function YearsContent({ getValues, onSubmitFilter, onClose, options }) {
  const formProps = useForm({
    defaultValues: {
      initialYear: getValues("initialYear") || initialYearSelectDefaultValue,
      finalYear: getValues("finalYear") || finalYearSelectDefaultValue,
    },
  })

  const onSubmit = formProps.handleSubmit((data) => {
    saveOnSessionStorage({
      name: "initialYear",
      value: data.initialYear,
    })
    saveOnSessionStorage({
      name: "finalYear",
      value: data.finalYear,
    })

    onSubmitFilter(data)
    onClose()
  })

  return (
    <>
      <div className="filters-drawer__content">
        <Text size="lg" weight="400" className="filters-drawer__title">
          defina uma faixa entre anos
        </Text>
        <div className="step__vehicle-year-row">
          <div className="step__vehicle-year-col">
            <Select
              className="step__year step__year--vehicle-deal-properties"
              name="initialYear"
              label=""
              description="ex. 2001"
              minSearch={2}
              options={options}
              validators={{
                rules: {
                  validate: {
                    required: (value) =>
                      !!value && value !== initialYearSelectDefaultValue,
                  },
                },
              }}
              onBlur={() => {
                const initialYear = formProps.getValues("initialYear")
                const initialYearSession = getFromSessionStorage({
                  name: "initialYear",
                })

                if (!initialYear) {
                  let initialYearDefault = ""

                  const finalYear = formProps.getValues("finalYear")
                  if (finalYear !== finalYearSelectDefaultValue) {
                    initialYearDefault =
                      initialYearSession ||
                      finalYear ||
                      new Date().getFullYear()
                  } else {
                    initialYearDefault =
                      initialYearSession || new Date().getFullYear()
                  }

                  formProps.setValue("initialYear", initialYearDefault, {
                    shouldValidate: true,
                  })
                }
              }}
              onSelectItem={({ value }) => {
                let initialYear = value
                const finalYear = formProps.getValues("finalYear")

                if (initialYear && finalYear && initialYear > finalYear) {
                  initialYear = finalYear
                  formProps.setValue("initialYear", initialYear, {
                    shouldValidate: true,
                  })
                }
              }}
              required={true}
              control={formProps.control}
              formProps={formProps}
            />
          </div>
          <div className="step__vehicle-year-col">
            <Select
              className="step__year step__year--vehicle-deal-properties"
              name="finalYear"
              label=""
              description="ex. 2001"
              minSearch={2}
              options={options}
              validators={{
                rules: {
                  validate: {
                    required: (value) =>
                      !!value && value !== finalYearSelectDefaultValue,
                  },
                },
              }}
              onBlur={() => {
                const finalYear = formProps.getValues("finalYear")
                const finalYearSession = getFromSessionStorage({
                  name: "finalYear",
                })

                if (!finalYear) {
                  const finalYearDefault =
                    finalYearSession || new Date().getFullYear()
                  formProps.setValue("finalYear", finalYearDefault, {
                    shouldValidate: true,
                  })
                }
              }}
              control={formProps.control}
              formProps={formProps}
              onSelectItem={({ value }) => {
                let finalYear = value
                const initialYear = formProps.getValues("initialYear")

                if (initialYear && finalYear && initialYear > finalYear) {
                  finalYear = initialYear
                  formProps.setValue("finalYear", finalYear, {
                    shouldValidate: true,
                  })
                }
              }}
            />
          </div>
        </div>
      </div>
      <footer className="filters-drawer__footer">
        <Button onClick={onSubmit}>filtrar</Button>
      </footer>
    </>
  )
}
