import { useForm } from "react-hook-form"

// Components
import { Button, Text } from "@mobi/ds"
import { SheetSelect } from "components/SheetSelect"

export const modelFilterClearValues = { model: null }

export function ModelContent({ getValues, onSubmitFilter, onClose, options }) {
  const { watch, handleSubmit, setValue } = useForm({
    defaultValues: { model: getValues("model") },
  })

  const currentValue = watch("model")
  const hasValue = !!currentValue?.value

  const onClear = () => {
    onSubmitFilter(modelFilterClearValues)
    onClose()
  }

  const onSubmit = handleSubmit((data) => {
    onSubmitFilter(data)
    onClose()
  })

  const onSelectItem = (item) => {
    setValue("model", item)
  }

  return (
    <>
      <div className="filters-drawer__content">
        <Text size="lg" weight="400" className="filters-drawer__title">
          modelo
        </Text>
        <SheetSelect.Content
          options={options}
          name="model"
          currentValue={currentValue?.value}
          onSelectItem={onSelectItem}
          placeholder="digite um modelo"
        />
      </div>
      <footer className="filters-drawer__footer">
        <Button variant="secondary" onClick={onClear} disabled={!hasValue}>
          limpar
        </Button>
        <Button onClick={onSubmit} disabled={!hasValue}>
          filtrar
        </Button>
      </footer>
    </>
  )
}
