// Components
import { Button, Checkbox, Text } from "@mobi/ds"
import { useForm } from "react-hook-form"

// Utils
import isMobile from "@mobi/utils/browser/isMobile"

// Data
import { finalPlateOptions } from "./dataContent"
import { useState } from "react"

export const plateFinalFilterClearValues = {
  plateFinal: "",
}

export function PlateFinalContent({ getValues, onSubmitFilter, onClose }) {
  const [, setShouldResetOn] = useState(0)

  function getDefaultValues() {
    const plateFinal = getValues("plateFinal")

    const plateFinalDefaultValues = finalPlateOptions.reduce(
      (acc, current, index) => ({
        ...acc,
        [index]: plateFinal.includes(current.value),
      }),
      {}
    )

    return {
      plateFinal: plateFinalDefaultValues,
    }
  }

  const { control, handleSubmit, watch } = useForm({
    defaultValues: getDefaultValues(),
  })

  const plateFinalCurrentValue = watch("plateFinal")
  const hasValue = Object.values(plateFinalCurrentValue || {}).some(
    (option) => !!option
  )

  const handleResetWhenChange = () => setShouldResetOn((old) => old + 1)

  const onClear = () => {
    onSubmitFilter(plateFinalFilterClearValues)
    onClose()
  }

  const onSubmit = handleSubmit(({ plateFinal }) => {
    const checkedPlatesOptions = (Object.values(plateFinal) || []).reduce(
      (acc, current, index) =>
        current ? acc.concat(finalPlateOptions[index].value) : acc,
      []
    )

    onSubmitFilter({ plateFinal: checkedPlatesOptions.join("|") })
    onClose()
  })

  return (
    <>
      <div className="filters-drawer__content">
        <Text size="lg" weight="400" className="filters-drawer__title">
          {isMobile ? "selecione o final de placa" : "placa"}
        </Text>
        <fieldset>
          {finalPlateOptions.map(({ text }, index) => (
            <Checkbox
              key={text}
              name={`plateFinal[${index}]`}
              label={text}
              control={control}
              onChange={handleResetWhenChange}
            />
          ))}
        </fieldset>
      </div>
      <footer className="filters-drawer__footer">
        <Button variant="secondary" onClick={onClear} disabled={!hasValue}>
          limpar
        </Button>
        <Button onClick={onSubmit} disabled={!hasValue}>
          filtrar
        </Button>
      </footer>
    </>
  )
}
