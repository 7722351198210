import { useCallback } from "react"

// Utils
import { getFromSessionStorage } from "utils/storage"

const useTimeStamp = () => {
  const keyToPersistExpirationTime = "lastCodeSentTimestamp"
  const totalSecondsCounter = 120

  const isPersistedTimestampValid = useCallback(() => {
    const persistedExpirationTimestamp = getFromSessionStorage({
      name: keyToPersistExpirationTime,
    })

    const result =
      (Date.now() - persistedExpirationTimestamp) / 1000 < totalSecondsCounter
    return result
  }, [keyToPersistExpirationTime])

  const getExpirationTimestamp = () => {
    const persistedExpirationTimestamp = getFromSessionStorage({
      name: keyToPersistExpirationTime,
    })

    const secondsBetweenPersistedAndNow =
      (Date.now() - persistedExpirationTimestamp) / 1000

    const isPersistedTimestampValid =
      !!persistedExpirationTimestamp &&
      secondsBetweenPersistedAndNow < totalSecondsCounter

    const currentTime = isPersistedTimestampValid
      ? new Date(Number(persistedExpirationTimestamp))
      : new Date()
    currentTime.setSeconds(currentTime.getSeconds() + totalSecondsCounter)

    return currentTime
  }

  return {
    isPersistedTimestampValid,
    getExpirationTimestamp,
  }
}

export default useTimeStamp
