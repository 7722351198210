export const UF_OPTIONS = [
	{ label: "Acre", value: "AC" },
	{ label: "Alagoas", value: "AL" },
	{ label: "Amapá", value: "AP" },
	{ label: "Amazonas", value: "AM" },
	{ label: "Bahia", value: "BA" },
	{ label: "Ceará", value: "CE" },
	{ label: "Distrito Federal", value: "DF" },
	{ label: "Espírito Santo", value: "ES" },
	{ label: "Goiás", value: "GO" },
	{ label: "Maranhão", value: "MA" },
	{ label: "Mato Grosso", value: "MT" },
	{ label: "Mato Grosso do Sul", value: "MS" },
	{ label: "Minas Gerais", value: "MG" },
	{ label: "Pará", value: "PA" },
	{ label: "Paraíba", value: "PB" },
	{ label: "Paraná", value: "PR" },
	{ label: "Pernambuco", value: "PE" },
	{ label: "Piauí", value: "PI" },
	{ label: "Rio de Janeiro", value: "RJ" },
	{ label: "Rio Grande do Norte", value: "RN" },
	{ label: "Rio Grande do Sul", value: "RS" },
	{ label: "Rondônia", value: "RO" },
	{ label: "Roraima", value: "RR" },
	{ label: "Santa Catarina", value: "SC" },
	{ label: "São Paulo", value: "SP" },
	{ label: "Sergipe", value: "SE" },
	{ label: "Tocantins", value: "TO" }
]

export const UF_ACRONYM_OPTIONS = [
	{ label: "AC", value: "AC" },
	{ label: "AL", value: "AL" },
	{ label: "AP", value: "AP" },
	{ label: "AM", value: "AM" },
	{ label: "BA", value: "BA" },
	{ label: "CE", value: "CE" },
	{ label: "DF", value: "DF" },
	{ label: "ES", value: "ES" },
	{ label: "GO", value: "GO" },
	{ label: "MA", value: "MA" },
	{ label: "MT", value: "MT" },
	{ label: "MS", value: "MS" },
	{ label: "MG", value: "MG" },
	{ label: "PA", value: "PA" },
	{ label: "PB", value: "PB" },
	{ label: "PR", value: "PR" },
	{ label: "PE", value: "PE" },
	{ label: "PI", value: "PI" },
	{ label: "RJ", value: "RJ" },
	{ label: "RN", value: "RN" },
	{ label: "RS", value: "RS" },
	{ label: "RO", value: "RO" },
	{ label: "RR", value: "RR" },
	{ label: "SC", value: "SC" },
	{ label: "SP", value: "SP" },
	{ label: "SE", value: "SE" },
	{ label: "TO", value: "TO" }
]

export const NATIONALITY_OPTIONS = [
	{ label: "BRASILEIRA", value: "BRASILEIRA" },
	{ label: "ALEMÃ", value: "ALEMÃ" },
	{ label: "AMERICANA", value: "AMERICANA" },
	{ label: "ANGOLANA", value: "ANGOLANA" },
	{ label: "ARGELINA", value: "ARGELINA" },
	{ label: "ARGENTINA", value: "ARGENTINA" },
	{ label: "ARMENIA", value: "ARMENIA" },
	{ label: "AUSTRALIANA", value: "AUSTRALIANA" },
	{ label: "AUSTRIACA", value: "AUSTRIACA" },
	{ label: "BOLIVIANA", value: "BOLIVIANA" },
	{ label: "BRITÂNICA", value: "BRITANICA" },
	{ label: "BULGARA", value: "BULGARA" },
	{ label: "CABO VERDIANA", value: "CABO_VERDIANA" },
	{ label: "CAMARONESA", value: "CAMARONESA" },
	{ label: "CHILENA", value: "CHILENA" },
	{ label: "CHINESA", value: "CHINESA" },
	{ label: "COLOMBIANA", value: "COLOMBIANA" },
	{ label: "COSTA RIQUENHA", value: "COSTA_RIQUENHA" },
	{ label: "CUBANA", value: "CUBANA" },
	{ label: "DINAMARQUESA", value: "DINAMARQUESA" },
	{ label: "EGÍPCIA", value: "EGIPCIA" },
	{ label: "EQUATORIANA", value: "EQUATORIANA" },
	{ label: "ESCOCESA", value: "ESCOCESA" },
	{ label: "ESLOVENA", value: "ESLOVENA" },
	{ label: "ESPANHOLA", value: "ESPANHOLA" },
	{ label: "FINLANDESA", value: "FINLANDESA" },
	{ label: "FRANCESA", value: "FRANCESA" },
	{ label: "GREGA", value: "GREGA" },
	{ label: "HOLANDESA", value: "HOLANDESA" },
	{ label: "HÚNGARA", value: "HUNGARA" },
	{ label: "INDIANA", value: "INDIANA" },
	{ label: "INGLESA", value: "INGLESA" },
	{ label: "IRANIANA", value: "IRANIANA" },
	{ label: "IRAQUIANA", value: "IRAQUIANA" },
	{ label: "IRLANDESA", value: "IRLANDESA" },
	{ label: "ITALIANA", value: "ITALIANA" },
	{ label: "JAPONESA", value: "JAPONESA" },
	{ label: "LIBANESA", value: "LIBANESA" },
	{ label: "MEXICANA", value: "MEXICANA" },
	{ label: "MOÇAMBICANA", value: "MOCAMBICANA" },
	{ label: "NIGERIANA", value: "NIGERIANA" },
	{ label: "NORTE COREANA", value: "NORTE_COREANA" },
	{ label: "NORUEGUESA", value: "NORUEGUESA" },
	{ label: "OUTROS", value: "OUTROS" },
	{ label: "PARAGUAIA", value: "PARAGUAIA" },
	{ label: "PERUANA", value: "PERUANA" },
	{ label: "POLONESA", value: "POLONESA" },
	{ label: "PORTUGUESA", value: "PORTUGUESA" },
	{ label: "QUENIANA", value: "QUENIANA" },
	{ label: "ROMENA", value: "ROMENA" },
	{ label: "RUSSA", value: "RUSSA" },
	{ label: "SERVIA", value: "SERVIA" },
	{ label: "SIRIA", value: "SIRIA" },
	{ label: "SUECA", value: "SUECA" },
	{ label: "SUIÇA", value: "SUICA" },
	{ label: "SUL COREANA", value: "SUL_COREANA" },
	{ label: "SURINAMESA", value: "SURINAMESA" },
	{ label: "TRINITARIA", value: "TRINITARIA" },
	{ label: "TURCA", value: "TURCA" },
	{ label: "UCRANIANA", value: "UCRANIANA" },
	{ label: "URUGUAIA", value: "URUGUAIA" },
	{ label: "VENEZUELANA", value: "VENEZUELANA" }
].sort((a, b) => {
	if (a.label === "BRASILEIRA") return -1
	if (b.label === "BRASILEIRA") return 1
	return a.label.localeCompare(b.label)
})

export const OCCUPATION_CATEGORY_OPTIONS = [
	{
		label: "Assalariado(a)",
		value: "ASSALARIADO"
	},
	{
		label: "Aposentado(a)",
		value: "APOSENTADO"
	},
	{
		label: "Autônomo(a)",
		value: "AUTONOMO"
	},
	{
		label: "Profissional liberal",
		value: "PROFISSIONAL_LIBERAL"
	},
	{
		label: "Sócio(a) Proprietário(a)",
		value: "SOCIO_PROPRIETARIO"
	}
]

export const MARITAL_STATUS_OPTIONS = [
	{
		label: "Solteiro(a)",
		value: "SOLTEIRO"
	},
	{
		label: "Casado(a)",
		value: "CASADO"
	},
	{
		label: "Divorciado(a)",
		value: "DIVORCIADO"
	},
	{
		label: "Viúvo(a)",
		value: "VIUVO"
	}
]

export const FULL_MARITAL_STATUS_OPTIONS = [
	{
		value: "SOLTEIRO",
		label: "Solteiro(a)"
	},
	{
		value: "DIVORCIADO",
		label: "Divorciado(a)"
	},
	{
		value: "VIUVO",
		label: "Viúvo(a)"
	},
	{
		value: "CASADO",
		label: "Casado(a)"
	},
	{
		value: "DESQUITADO",
		label: "Desquitado(a)"
	},
	{
		value: "SEPARADO_JUDICIALMENTE",
		label: "Separado(a) Judicialmente"
	}
]

export const OCCUPATION_OPTIONS = [
	{ value: "AÇOUGUEIRO", label: "AÇOUGUEIRO" },
	{ value: "ADMINISTRADOR", label: "ADMINISTRADOR" },
	{ value: "ADVOGADO", label: "ADVOGADO" },
	{ value: "AEROVIÁRIOS", label: "AEROVIÁRIOS" },
	{ value: "AFIADOR", label: "AFIADOR" },
	{ value: "AGENCIADOR DE PROPAGANDA", label: "AGENCIADOR DE PROPAGANDA" },
	{
		value: "AGENCIADOR SERVIÇOS (TRANSP.)",
		label: "AGENCIADOR SERVIÇOS (TRANSP.)"
	},
	{ value: "AGENTE ADMISTRATIVO", label: "AGENTE ADMISTRATIVO" },
	{
		value: "PROFISS SERVICOS FUNERARIOS",
		label: "PROFISS SERVICOS FUNERARIOS"
	},
	{ value: "AGENTE VIAGEM/GUIA/AFINS", label: "AGENTE VIAGEM/GUIA/AFINS" },
	{ value: "AGRONOMO", label: "AGRONOMO" },
	{ value: "AJUDANTE GERAL/SERVENTE", label: "AJUDANTE GERAL/SERVENTE" },
	{
		value: "AJUDANTE PRODUÇÃO/MEIO OFICIAL",
		label: "AJUDANTE PRODUÇÃO/MEIO OFICIAL"
	},
	{ value: "AJUSTADOR/RETIFICADOR", label: "AJUSTADOR/RETIFICADOR" },
	{ value: "ALFAIATE", label: "ALFAIATE" },
	{ value: "ALMOXARIFE", label: "ALMOXARIFE" },
	{ value: "ANALISTA DE CARGOS/RH", label: "ANALISTA DE CARGOS/RH" },
	{
		value: "ANALISTA CRÉD/COBRANÇA/CAD/GAR",
		label: "ANALISTA CRÉD/COBRANÇA/CAD/GAR"
	},
	{
		value: "ANALISTA CUSTOS/PROJ/SERV/ECON",
		label: "ANALISTA CUSTOS/PROJ/SERV/ECON"
	},
	{ value: "ANALISTA/MÉTODO/HARD/SOFT", label: "ANALISTA/MÉTODO/HARD/SOFT" },
	{
		value: "ANALISTA PRODUÇÃO/CRONOANALIS",
		label: "ANALISTA PRODUÇÃO/CRONOANALIS"
	},
	{ value: "ANALISTA SISTEMAS", label: "ANALISTA SISTEMAS" },
	{ value: "APOSENTADO", label: "APOSENTADO" },
	{ value: "ARMARDOR DE FERRAGENS", label: "ARMARDOR DE FERRAGENS" },
	{ value: "ARQUITETO", label: "ARQUITETO" },
	{
		value: "ARRENDATÁRIO/MEEIRO/PARCELEIRO",
		label: "ARRENDATÁRIO/MEEIRO/PARCELEIRO"
	},
	{
		value: "ARTISTA PLASTIC/PINTOR/ESCULT",
		label: "ARTISTA PLASTIC/PINTOR/ESCULT"
	},
	{
		value: "ASSESSOR/SUPERINTENDENTE/CHEFE",
		label: "ASSESSOR/SUPERINTENDENTE/CHEFE"
	},
	{ value: "ASSISTENTE/AGENTES SOCIAIS", label: "ASSISTENTE/AGENTES SOCIAIS" },
	{
		value: "ATEN ENFERM/CUIDADOR/AG SAUDE",
		label: "ATEN ENFERM/CUIDADOR/AG SAUDE"
	},
	{
		value: "ATLETA PROF/TECNICO/ARBITROS",
		label: "ATLETA PROF/TECNICO/ARBITROS"
	},
	{
		value: "ATOR/DIR ESPETACULOS PUBLICOS",
		label: "ATOR/DIR ESPETACULOS PUBLICOS"
	},
	{ value: "ATUARIO/MATEMATICO", label: "ATUARIO/MATEMATICO" },
	{ value: "AUTÔNOMO", label: "AUTÔNOMO" },
	{
		value: "AUX ESCR/FATUR/BILH/CONF/EXPED",
		label: "AUX ESCR/FATUR/BILH/CONF/EXPED"
	},
	{
		value: "AUX. COZINHA/COSTURA/LIMPEZA",
		label: "AUX. COZINHA/COSTURA/LIMPEZA"
	},
	{ value: "AUX ESCRITORIO", label: "AUX ESCRITORIO" },
	{ value: "AUXILIAR LABORATORIO", label: "AUXILIAR LABORATORIO" },
	{ value: "BAILARINO/COREOGRAFO", label: "BAILARINO/COREOGRAFO" },
	{ value: "BANCARIO/ECONOMIARIO", label: "BANCARIO/ECONOMIARIO" },
	{ value: "BENEFICIÁRIO", label: "BENEFICIÁRIO" },
	{
		value: "BIBLIOT/ARQUIV/MUSEOL/ARQUEOL",
		label: "BIBLIOT/ARQUIV/MUSEOL/ARQUEOL"
	},
	{ value: "BIOLOGO/BIOMEDICO", label: "BIOLOGO/BIOMEDICO" },
	{
		value: "BOMBEIRO INSTAL GAS/AGUA/ESG",
		label: "BOMBEIRO INSTAL GAS/AGUA/ESG"
	},
	{ value: "BORRACHEIRO, DEDETIZADOR", label: "BORRACHEIRO, DEDETIZADOR" },
	{ value: "PROFISSIONAL ESTETICA", label: "PROFISSIONAL ESTETICA" },
	{
		value: "CALCETEIRO/CANTEIRO/MARM/VIDRA",
		label: "CALCETEIRO/CANTEIRO/MARM/VIDRA"
	},
	{
		value: "CALHISTA/TELHADISTA/ANTENISTA",
		label: "CALHISTA/TELHADISTA/ANTENISTA"
	},
	{ value: "CANTOR/COMPOSITOR", label: "CANTOR/COMPOSITOR" },
	{ value: "CAPITALISTA", label: "CAPITALISTA" },
	{ value: "CARPINTEIRO/MARCENEIRO", label: "CARPINTEIRO/MARCENEIRO" },
	{ value: "CARTORÁRIO", label: "CARTORÁRIO" },
	{ value: "CASEIRO/JARDINEIRO", label: "CASEIRO/JARDINEIRO" },
	{
		value: "CAVALARIÇOS/FERRADOR/TRATADOR",
		label: "CAVALARIÇOS/FERRADOR/TRATADOR"
	},
	{ value: "CHEFE INTERMEDIARIO", label: "CHEFE INTERMEDIARIO" },
	{
		value: "CHEFE COZINHA/MAITRE/BARMAN",
		label: "CHEFE COZINHA/MAITRE/BARMAN"
	},
	{
		value: "COBRADOR/BILHET TRANSP COLET",
		label: "COBRADOR/BILHET TRANSP COLET"
	},
	{ value: "COMANDANTE DE EMBARCACOES", label: "COMANDANTE DE EMBARCACOES" },
	{
		value: "COMENT/RADIAL/LOCUT RADIO/TV",
		label: "COMENT/RADIAL/LOCUT RADIO/TV"
	},
	{ value: "COMERCIÁRIOS", label: "COMERCIÁRIOS" },
	{
		value: "COMERC EXTERIOR/OUTROS COMERC",
		label: "COMERC EXTERIOR/OUTROS COMERC"
	},
	{ value: "COMISSARIO DE BORDO", label: "COMISSARIO DE BORDO" },
	{
		value: "COMUNICOLOGO/APRESENTADORES",
		label: "COMUNICOLOGO/APRESENTADORES"
	},
	{ value: "CONTADOR/PERITOS CONTÁBEIS", label: "CONTADOR/PERITOS CONTÁBEIS" },
	{
		value: "CONTÍNUO/ENTREGADOR/MOTOQUEIRO",
		label: "CONTÍNUO/ENTREGADOR/MOTOQUEIRO"
	},
	{
		value: "CONTRAMESTRE DE EMBARCACOES",
		label: "CONTRAMESTRE DE EMBARCACOES"
	},
	{
		value: "CORRETOR IMOV/SEG/TITULO VLR",
		label: "CORRETOR IMOV/SEG/TITULO VLR"
	},
	{ value: "CORTADOR", label: "CORTADOR" },
	{
		value: "COSTUREIRA/BORDAD/OVERL/CASEA",
		label: "COSTUREIRA/BORDAD/OVERL/CASEA"
	},
	{ value: "TRABALHADOR DE GRANJA", label: "TRABALHADOR DE GRANJA" },
	{ value: "DECORADOR", label: "DECORADOR" },
	{ value: "DELEGADO DE POLICIA", label: "DELEGADO DE POLICIA" },
	{ value: "DEMONSTRADOR", label: "DEMONSTRADOR" },
	{ value: "DENTISTA", label: "DENTISTA" },
	{
		value: "DEPUT EST/FED SENAD/VEREADOR",
		label: "DEPUT EST/FED SENAD/VEREADOR"
	},
	{
		value: "DESEMB/JUIZ/POD JUDIC/TRIB SUP",
		label: "DESEMB/JUIZ/POD JUDIC/TRIB SUP"
	},
	{ value: "DESENHISTA COMERCIAL", label: "DESENHISTA COMERCIAL" },
	{ value: "DESENHISTA INDUSTRIAL", label: "DESENHISTA INDUSTRIAL" },
	{
		value: "DESENHISTA TECNICO/PROJETISTA",
		label: "DESENHISTA TECNICO/PROJETISTA"
	},
	{ value: "DESPACHANTE", label: "DESPACHANTE" },
	{ value: "DIGITADOR", label: "DIGITADOR" },
	{ value: "DIPLOMATA", label: "DIPLOMATA" },
	{ value: "DIRETOR DE EMPRESAS", label: "DIRETOR DE EMPRESAS" },
	{ value: "DIRETOR ESTABELECIM ENSINO", label: "DIRETOR ESTABELECIM ENSINO" },
	{ value: "DONA DE CASA", label: "DONA DE CASA" },
	{ value: "ECONOMISTA", label: "ECONOMISTA" },
	{ value: "ELETRICISTA DE INSTALAÇÕES", label: "ELETRICISTA DE INSTALAÇÕES" },
	{ value: "ELETRICISTA INDUSTRIAL.", label: "ELETRICISTA INDUSTRIAL." },
	{ value: "ELETRICISTA INDUSTRIAL", label: "ELETRICISTA INDUSTRIAL" },
	{
		value: "ELETR VEIC/MAQ/ELETRON/TELECOM",
		label: "ELETR VEIC/MAQ/ELETRON/TELECOM"
	},
	{ value: "EMPREGADO DOMESTICO", label: "EMPREGADO DOMESTICO" },
	{
		value: "EMPREG RESTAUR, BUFE, LANCH",
		label: "EMPREG RESTAUR, BUFE, LANCH"
	},
	{ value: "EMPREGADO DOMÉSTICO.", label: "EMPREGADO DOMÉSTICO." },
	{
		value: "EMPRESARIO/PRODUTOR ESPETAC",
		label: "EMPRESARIO/PRODUTOR ESPETAC"
	},
	{ value: "ENFERMEIRO/NUTRICIONISTA", label: "ENFERMEIRO/NUTRICIONISTA" },
	{ value: "ENGENHEIRO", label: "ENGENHEIRO" },
	{ value: "ESPOLIO", label: "ESPOLIO" },
	{ value: "ESTATISTICO", label: "ESTATISTICO" },
	{
		value: "ESTIVADOR/CARREGADOR/EMBALAD",
		label: "ESTIVADOR/CARREGADOR/EMBALAD"
	},
	{
		value: "ESTUDANTE/BOLSISTA/ESTAGIARIO",
		label: "ESTUDANTE/BOLSISTA/ESTAGIARIO"
	},
	{ value: "FARMACEUTICO", label: "FARMACEUTICO" },
	{ value: "FEIRANTE", label: "FEIRANTE" },
	{ value: "FERRAMENTEIRO", label: "FERRAMENTEIRO" },
	{ value: "FISCAL", label: "FISCAL" },
	{ value: "FISICO", label: "FISICO" },
	{
		value: "FISIOTERAPEUTA/TO/PREP FÍSICO",
		label: "FISIOTERAPEUTA/TO/PREP FÍSICO"
	},
	{ value: "FONOAUDIOLOGO", label: "FONOAUDIOLOGO" },
	{
		value: "FRENTISTA/MANOB/LAV CARRO/LUBR",
		label: "FRENTISTA/MANOB/LAV CARRO/LUBR"
	},
	{ value: "FRESADOR", label: "FRESADOR" },
	{
		value: "FUNCIONÁRIO EMPRESA PÚBLICA",
		label: "FUNCIONÁRIO EMPRESA PÚBLICA"
	},
	{
		value: "FUNCIONARIO PUB CIVIL APOSENT",
		label: "FUNCIONARIO PUB CIVIL APOSENT"
	},
	{ value: "FUNDIDOR", label: "FUNDIDOR" },
	{ value: "GARIMPEIRO", label: "GARIMPEIRO" },
	{ value: "GEOGRAFO", label: "GEOGRAFO" },
	{ value: "GEOLOGO", label: "GEOLOGO" },
	{ value: "GERENTE", label: "GERENTE" },
	{
		value: "PROF HOTEL/PORT/COZIN/GARCOM",
		label: "PROF HOTEL/PORT/COZIN/GARCOM"
	},
	{
		value: "IMPRESSOR/LINOTIPIST/BLOQ/OPER",
		label: "IMPRESSOR/LINOTIPIST/BLOQ/OPER"
	},
	{
		value: "INSPETOR CONTROLE QUALIDADE",
		label: "INSPETOR CONTROLE QUALIDADE"
	},
	{ value: "INSTRUMENTISTA", label: "INSTRUMENTISTA" },
	{ value: "JOALHEIRO/OURIVES", label: "JOALHEIRO/OURIVES" },
	{ value: "JORNALEIRO", label: "JORNALEIRO" },
	{ value: "JORNALISTA E AFINS", label: "JORNALISTA E AFINS" },
	{
		value: "LANTERN/PINTOR VEICULO METALIC",
		label: "LANTERN/PINTOR VEICULO METALIC"
	},
	{ value: "LEILOEIRO/AVALIADOR", label: "LEILOEIRO/AVALIADOR" },
	{ value: "LEITURISTA", label: "LEITURISTA" },
	{
		value: "LIXEIRO/GARI/VARREDOR/LIMPEZA",
		label: "LIXEIRO/GARI/VARREDOR/LIMPEZA"
	},
	{
		value: "GUARDADOR DE CARRO/ENGRAXATE",
		label: "GUARDADOR DE CARRO/ENGRAXATE"
	},
	{ value: "MANUTENÇÃO", label: "MANUTENÇÃO" },
	{
		value: "MAQ/FOGUISTA EMBARC/LOCOMOTIV",
		label: "MAQ/FOGUISTA EMBARC/LOCOMOTIV"
	},
	{ value: "MARCENEIRO", label: "MARCENEIRO" },
	{ value: "MARINHEIRO", label: "MARINHEIRO" },
	{
		value: "PROF MANUT/OPER EQUIP INDUST",
		label: "PROF MANUT/OPER EQUIP INDUST"
	},
	{ value: "MECANICO MANUT VEICULO/MAQ", label: "MECANICO MANUT VEICULO/MAQ" },
	{ value: "MECÂNICO MÁQ/REFRIGERAÇÃO", label: "MECÂNICO MÁQ/REFRIGERAÇÃO" },
	{ value: "MEDICO", label: "MEDICO" },
	{
		value: "MESTRE/ENCARREG PROD INDUSTR",
		label: "MESTRE/ENCARREG PROD INDUSTR"
	},
	{ value: "METALURGICO/SIDERURGICO", label: "METALURGICO/SIDERURGICO" },
	{ value: "METEOROLOGISTA", label: "METEOROLOGISTA" },
	{ value: "MILITAR", label: "MILITAR" },
	{ value: "MILITAR REFORMADO", label: "MILITAR REFORMADO" },
	{
		value: "MEMBROS MINIST PUBLICO UNIAO",
		label: "MEMBROS MINIST PUBLICO UNIAO"
	},
	{ value: "MINISTRO/GOV/PREFEITO/SECR", label: "MINISTRO/GOV/PREFEITO/SECR" },
	{ value: "MODELO DE MODAS", label: "MODELO DE MODAS" },
	{ value: "MOTORISTA AUTÔNOMO/TAXISTA", label: "MOTORISTA AUTÔNOMO/TAXISTA" },
	{
		value: "MOTORISTA VEIC TRANSP CARGA",
		label: "MOTORISTA VEIC TRANSP CARGA"
	},
	{
		value: "MOTORISTA VEIC TRANSP PASSAG",
		label: "MOTORISTA VEIC TRANSP PASSAG"
	},
	{ value: "MUSICO", label: "MUSICO" },
	{
		value: "DIRETOR/ASSESSOR INDUSTRIAL",
		label: "DIRETOR/ASSESSOR INDUSTRIAL"
	},
	{ value: "DIRETOR/ASSESSOR SUPERIOR", label: "DIRETOR/ASSESSOR SUPERIOR" },
	{ value: "OFICIAL DA POLÍCIA MILITAR", label: "OFICIAL DA POLÍCIA MILITAR" },
	{
		value: "OFICIAL FORCAS ARMADAS/AUXIL",
		label: "OFICIAL FORCAS ARMADAS/AUXIL"
	},
	{ value: "OPERADOR DE CALDEIRAS", label: "OPERADOR DE CALDEIRAS" },
	{
		value: "OPER CÂMERA/AUDIO/DJ/SONOPL",
		label: "OPER CÂMERA/AUDIO/DJ/SONOPL"
	},
	{
		value: "OPER COMPUTADOR/SUP TÉCNICO",
		label: "OPER COMPUTADOR/SUP TÉCNICO"
	},
	{ value: "OPERADOR MÁQUINAS PRODUÇÃO", label: "OPERADOR MÁQUINAS PRODUÇÃO" },
	{ value: "OPERADOR EM GERAL/SOLDADOR", label: "OPERADOR EM GERAL/SOLDADOR" },
	{
		value: "OPER GUIND/EMPILH/TRATOR/CALD",
		label: "OPER GUIND/EMPILH/TRATOR/CALD"
	},
	{ value: "OUTROS DESENHISTAS", label: "OUTROS DESENHISTAS" },
	{ value: "OUTROS TECNICOS", label: "OUTROS TECNICOS" },
	{
		value: "OUTROS TRABALHADORES INDUST",
		label: "OUTROS TRABALHADORES INDUST"
	},
	{
		value: "OUTROS TRAB ENSINO NIVEL SUP",
		label: "OUTROS TRAB ENSINO NIVEL SUP"
	},
	{
		value: "OUTROS TRABALHADORES RURAIS",
		label: "OUTROS TRABALHADORES RURAIS"
	},
	{ value: "PADRE/PASTOR/RABINO", label: "PADRE/PASTOR/RABINO" },
	{
		value: "PEDREIRO/ENCAN/AZULEJ/IMPERM",
		label: "PEDREIRO/ENCAN/AZULEJ/IMPERM"
	},
	{ value: "PENSIONISTA", label: "PENSIONISTA" },
	{ value: "PESCADOR", label: "PESCADOR" },
	{ value: "PILOTO DE AERONAVES", label: "PILOTO DE AERONAVES" },
	{ value: "PINTOR DE PAREDES/GESSISTA", label: "PINTOR DE PAREDES/GESSISTA" },
	{
		value: "PORT EDIF/ASCENSS/GARAG/FAXIN",
		label: "PORT EDIF/ASCENSS/GARAG/FAXIN"
	},
	{ value: "PRENSISTA/ESTAMPADOR", label: "PRENSISTA/ESTAMPADOR" },
	{ value: "PREPARADOR DE MÁQUINAS", label: "PREPARADOR DE MÁQUINAS" },
	{ value: "PROCURADOR E ASSEMELHADOS", label: "PROCURADOR E ASSEMELHADOS" },
	{
		value: "PROF ENSINO FUNDAMENTAL/MEDIO",
		label: "PROF ENSINO FUNDAMENTAL/MEDIO"
	},
	{ value: "PROFESSOR/PESQ ENSINO SUP", label: "PROFESSOR/PESQ ENSINO SUP" },
	{
		value: "PROFISSIONAL LETRAS E ARTES",
		label: "PROFISSIONAL LETRAS E ARTES"
	},
	{ value: "PROGRAMADOR DE COMPUTADOR", label: "PROGRAMADOR DE COMPUTADOR" },
	{ value: "PROGRAMADOR DE PRODUÇÃO", label: "PROGRAMADOR DE PRODUÇÃO" },
	{ value: "PROMOTOR VENDA", label: "PROMOTOR VENDA" },
	{
		value: "PROPR ESTAB AGRI/PECU/FLOREST",
		label: "PROPR ESTAB AGRI/PECU/FLOREST"
	},
	{ value: "PROPR ESTAB COMERCIAL", label: "PROPR ESTAB COMERCIAL" },
	{
		value: "PROPR ESTAB PRESTACAO SERVIC",
		label: "PROPR ESTAB PRESTACAO SERVIC"
	},
	{ value: "PROPR ESTAB INDUSTRIAL", label: "PROPR ESTAB INDUSTRIAL" },
	{ value: "PROPRIETARIO IMOVEIS", label: "PROPRIETARIO IMOVEIS" },
	{ value: "PROPRIETARIO MICROEMPRESA", label: "PROPRIETARIO MICROEMPRESA" },
	{ value: "PROTETICO", label: "PROTETICO" },
	{ value: "PSICOLOGO", label: "PSICOLOGO" },
	{ value: "PUBLICITARIO", label: "PUBLICITARIO" },
	{ value: "QUIMICO", label: "QUIMICO" },
	{ value: "RELACOES PUBLICAS", label: "RELACOES PUBLICAS" },
	{
		value: "REPR COMERC/CAIX VIAJ/VENDED",
		label: "REPR COMERC/CAIX VIAJ/VENDED"
	},
	{ value: "SACERDOTE", label: "SACERDOTE" },
	{
		value: "SECRET/DATILOG/RECEP/TELFONIS",
		label: "SECRET/DATILOG/RECEP/TELFONIS"
	},
	{ value: "SECURITARIO", label: "SECURITARIO" },
	{ value: "SERRALHEIRO", label: "SERRALHEIRO" },
	{ value: "SERVENTUARIO DA JUSTICA", label: "SERVENTUARIO DA JUSTICA" },
	{ value: "SERV PUBLICO ESTADUAL", label: "SERV PUBLICO ESTADUAL" },
	{ value: "SERV PUBLICO FEDERERAL", label: "SERV PUBLICO FEDERERAL" },
	{ value: "SERV PUBLICO MUNICIPAL", label: "SERV PUBLICO MUNICIPAL" },
	{ value: "SOCIOLOGO", label: "SOCIOLOGO" },
	{
		value: "SUPERV/INSP/AGENT COMP/VEND",
		label: "SUPERV/INSP/AGENT COMP/VEND"
	},
	{ value: "TABELIAO", label: "TABELIAO" },
	{
		value: "TÉCNICO LABOR/ENFERM/ODONTO",
		label: "TÉCNICO LABOR/ENFERM/ODONTO"
	},
	{
		value: "TECNICO CONTABIL/ESTATISTICA",
		label: "TECNICO CONTABIL/ESTATISTICA"
	},
	{
		value: "TECNICO AGRIMENSURA/ AGRONOMIA",
		label: "TECNICO AGRIMENSURA/ AGRONOMIA"
	},
	{ value: "TECNICO BIOLOGIA", label: "TECNICO BIOLOGIA" },
	{
		value: "TECN ELETRIC/ELETRON/TELECOM",
		label: "TECN ELETRIC/ELETRON/TELECOM"
	},
	{ value: "TECNICO MECANICA", label: "TECNICO MECANICA" },
	{ value: "TECNICO QUIMICA", label: "TECNICO QUIMICA" },
	{ value: "TECNOLOGO", label: "TECNOLOGO" },
	{ value: "TORNEIRO", label: "TORNEIRO" },
	{
		value: "TRABALHADOR AGRICOLA/PECUARIA",
		label: "TRABALHADOR AGRICOLA/PECUARIA"
	},
	{ value: "TRABALHADOR DA PECUÁRIA", label: "TRABALHADOR DA PECUÁRIA" },
	{
		value: "TRABALHADOR DE CASA NOTURNA",
		label: "TRABALHADOR DE CASA NOTURNA"
	},
	{ value: "TRAB FABRICACAO BORRACHA", label: "TRAB FABRICACAO BORRACHA" },
	{ value: "TRAB FABRIC CALCADOS/COURO", label: "TRAB FABRIC CALCADOS/COURO" },
	{ value: "TRAB FABRICACAO PAPEL", label: "TRAB FABRICACAO PAPEL" },
	{ value: "TRAB FABRICACAO TEXTEIS", label: "TRAB FABRICACAO TEXTEIS" },
	{ value: "TRAB FABRICACAO ROUPAS", label: "TRAB FABRICACAO ROUPAS" },
	{
		value: "TRAB FABRICACAO ALIM/BEBIDAS",
		label: "TRAB FABRICACAO ALIM/BEBIDAS"
	},
	{ value: "TRAB PROCESSMTO QUIMICOS", label: "TRAB PROCESSMTO QUIMICOS" },
	{ value: "TRABALHADOR FUMO/CIG/CHARU", label: "TRABALHADOR FUMO/CIG/CHARU" },
	{ value: "TRAB USINAGEM DE METAIS", label: "TRAB USINAGEM DE METAIS" },
	{ value: "TRAB CONTABIL/CAIXA", label: "TRAB CONTABIL/CAIXA" },
	{ value: "TRAB ARTES GRAFICAS", label: "TRAB ARTES GRAFICAS" },
	{
		value: "TRABALHADOR CONTRUCAO CIVIL",
		label: "TRABALHADOR CONTRUCAO CIVIL"
	},
	{
		value: "TRAB FLOREST/EXTRAT/MEIO AMB",
		label: "TRAB FLOREST/EXTRAT/MEIO AMB"
	},
	{
		value: "VENDEDORES AUT/AMBUL/CAMELÔ",
		label: "VENDEDORES AUT/AMBUL/CAMELÔ"
	},
	{
		value: "VENDED VAREJISTA/ATACADISTA",
		label: "VENDED VAREJISTA/ATACADISTA"
	},
	{ value: "VETERINARIO/ZOOTECNISTA", label: "VETERINARIO/ZOOTECNISTA" },
	{ value: "VIGIA/SEGURANÇA/BOMBEIRO", label: "VIGIA/SEGURANÇA/BOMBEIRO" },
	{ value: "ZELADOR", label: "ZELADOR" }
]
