// Components
import { SheetSelectRoot } from "./components/SheetSelectRoot"
import { SheetSelectTrigger } from "./components/SheetSelectTrigger"
import { SheetSelectModal } from "./components/SheetSelectModal"
import { SheetSelectContent } from "../SheetSelectContent"

// Hooks
import { useSheetSelectField } from "./useSheetSelectField"

export function SheetSelectField({
  name,
  control,
  required,
  onChange,
  options,
  disabled,
  label,
  description,
  placeholder,
  getIcon,
  ...rest
}) {
  const sheetSelectProps = useSheetSelectField({
    name,
    control,
    required,
    onChange,
  })

  return (
    <SheetSelectRoot {...rest}>
      <SheetSelectTrigger
        icon={getIcon(sheetSelectProps.value)}
        label={label}
        value={sheetSelectProps.value?.label}
        onClick={sheetSelectProps.onOpen}
        disabled={disabled}
      />

      <SheetSelectModal
        isOpen={sheetSelectProps.isOpen}
        onClose={sheetSelectProps.onClose}
        label={label}
        description={description}
        isSubmitDisabled={!sheetSelectProps.value}
      >
        <SheetSelectContent
          placeholder={placeholder}
          name={name}
          options={options}
          currentValue={sheetSelectProps.value?.value}
          onSelectItem={sheetSelectProps.onSelectItem}
        />
      </SheetSelectModal>
    </SheetSelectRoot>
  )
}
