import { useState, useEffect, useContext } from "react"
import { useForm } from "react-hook-form"
import { MULTIPRODUCT_URL } from "gatsby-env-variables"

//Services
import services from "services"
import entities from "@entities"

//Contexts
import { useStep } from "@mobi/libraries/step"
import { Context } from "contexts/simulation"

import useLatestPropsRef from "hooks/useLatestPropsRef"

// Tracking
import analytics from "@mobi/libraries/analytics"

// Utils
import { getUUIDFromUrl, resetFocus } from "utils/browser"
import { fillField } from "utils/forms"
import { toInstallmentsList } from "services/mappers"
import { currencyToFloat } from "@mobi/utils/formatters/currency"
import { gtm } from "utils/tracking"
import { getFromSessionStorage, saveOnSessionStorage } from "utils/storage"
import { navigateWithQueryString } from "utils/browser"

// TODO: Substituir condicional por KEY do erro quando estiver implementado na API
const SIMULATION_CHANGE_START_MESSAGE_OF_ERROR =
  "Desculpe, a mudança das opções de parcelas e valor de entrada não está disponível neste momento, mas é possível finalizar a simulação e mudar os valores diretamente com a loja"

const useSimulation = () => {
  const [stepData, setStepData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isFetchingSimulation, setIsFetchingSimulation] = useState(false)
  const [isSimulationChangesBlocked, setIsSimulationChangesBlocked] =
    useState(false)
  const { next } = useStep()

  const stepName = "simulation"
  const uuid = getUUIDFromUrl()

  const { simulation, setSimulation } = useContext(Context)

  const isClosedFlow = getFromSessionStorage({ name: "isClosedFlow" }) || false

  const formProps = useForm({
    mode: "onChange",
    defaultValues: {
      downPayment: "",
      numberOfInstallments: "",
      installments: "",
      financeProtectionInsurance: true,
    },
  })

  const latestSimulationRef = useLatestPropsRef(simulation)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const startSimulationStep = async () => {
      const sourceUrl = window?.location?.href

      const isSimulationDeal =
        latestSimulationRef.current.currentFormStep === "simulation-deal"

      // TODO: Repensar uma condição melhor para evitar esse update duplo em anúncios da tela de negado
      if (!isSimulationDeal) {
        await services.simulation.update({}, { sourceUrl }).catch(() => {
          return navigateWithQueryString("/indisponivel/", {
            leadUUID: uuid.value,
          })
        })
      }

      const response = await services.simulation.getSimulation(isSimulationDeal)

      if (response) {
        const {
          isAbleToProceed,
          vehicleDealProperties,
          streetName,
          externalToken,
        } = response

        saveOnSessionStorage({
          name: "sellerStreetName",
          value: streetName,
        })

        if (isAbleToProceed) {
          onReceiveSimulation({
            data: response,
            formProps,
            setIsLoading,
            setStepData,
          })
          resetFocus()

          analytics.track("simulationSuccess", response)
          setSimulation(response)

          const gtmParamers = {
            event: "Simulation_Approved",
            lead_aprovado: true,
            clicked_on_financing_button: true,
            clicked_on_message_button: false,
            vehicle_brand: vehicleDealProperties.brandName,
            vehicle_model: vehicleDealProperties.modelName,
            vehicle_year: vehicleDealProperties.vehicleYear,
            vehicle_price: vehicleDealProperties.vehicleValue,
            vehicle_km: parseInt(vehicleDealProperties.vehicleKm),
            vehicle_state: vehicleDealProperties.dealState,
            vehicle_city: vehicleDealProperties.dealCity,
            deal_id: vehicleDealProperties.dealID,
          }

          const closedFlowGtmParamers = {
            ...gtmParamers,
            icConversionId: response.gtm.icConversionId,
            icFeiraoIcarros: response.gtm.feirao,
            icTypeConversion: response.gtm.conversionType,
            icAnuncianteTipo: response.gtm.seller_type,
            userEmail: response.gtm.email,
            userPhone: response.gtm.phoneNumber,
            icUserScore: response.gtm.userScore,
            icEmail: response.gtm.icEmail,
            origemConversao: response.gtm.origemConversao,
          }

          if (isClosedFlow) {
            gtm(closedFlowGtmParamers)
          }

          analytics.track("pageLoad", {})

          return setIsLoading(false)
        } else {
          return navigateWithQueryString("/reprovado/", {
            leadUUID: uuid.value,
          })
          // Redireciona para o Multiprodutos
          // return window.location.replace(
          //   `${MULTIPRODUCT_URL}?token=${externalToken}`
          // )
        }
      } else {
        return navigateWithQueryString("/indisponivel/", {
          leadUUID: uuid.value,
        })
      }
    }

    startSimulationStep()
  }, [])

  const onSubmit = async (payload) => {
    setIsFetchingSimulation(true)
    const response = await requestSimulation({
      payload,
      stepName,
      setIsLoading: setIsFetchingSimulation,
    })

    if (response) {
      onReceiveSimulation({
        data: response.data,
        formProps,
        setStepData,
        stepData,
        updateCurrentSimulation: true,
      })
    }
  }

  const requestSimulation = async ({ payload, setIsLoading }) => {
    const response = await services.simulation
      .update(payload, { sourceUrl: window?.location?.href }, true)
      .catch((error) => {
        const needToBlockTransactionChanges = (
          error?.response?.data?.message || ""
        ).startsWith(SIMULATION_CHANGE_START_MESSAGE_OF_ERROR)

        const installments = stepData?.availableInstallmentOptions
          ? toInstallmentsList(stepData?.availableInstallmentOptions)
          : []
        const initialInstallment = installments.find(
          (item) => item.selected || item.suggested
        )

        payload = {
          ...payload,
          installments: initialInstallment?.value,
          numberOfInstallments: initialInstallment?.label,
        }

        fillField({
          fieldName: "downPayment",
          value: payload?.defaultDownPayment,
          formProps,
        })
        fillField({
          fieldName: "numberOfInstallments",
          value: initialInstallment?.value,
          formProps,
        })
        fillField({
          fieldName: "installments",
          value: initialInstallment?.label,
          formProps,
        })

        setIsSimulationChangesBlocked(needToBlockTransactionChanges)
      })

    if (response?.status === 200) {
      const { data } = response

      const mountSimulationOptions = {
        ...data.simulation,
        simulationInstallments: data.simulationInstallments,
      }
      const newPayload = {
        ...data,
        data: {
          ...response.data,
          simulationOptions: entities.SimulationOptions(mountSimulationOptions),
        },
      }
      setIsLoading(false)
      return newPayload
    }

    setIsLoading(false)
  }

  const requestNewSimulation = async (downPayment, installments) => {
    setIsFetchingSimulation(true)

    const newPayload = {
      ...formProps.getValues(),
      numberOfInstallments:
        installments || formProps.getValues()?.numberOfInstallments,
      downPayment: currencyToFloat(downPayment),
    }

    const response = await requestSimulation({
      payload: newPayload,
      stepName,
      setIsLoading: setIsFetchingSimulation,
    })

    if (response?.data?.simulationOptions) {
      const { data } = response

      analytics.track("simulationUpdate", data)

      onReceiveSimulation({
        data,
        formProps,
        setStepData,
        stepData,
        updateCurrentSimulation: true,
      })
    }
  }

  const getSelectedOption = ({ numberOfInstallments, installments = [] }) => {
    return installments.find((item) => {
      if (item.value === parseInt(numberOfInstallments)) {
        return item
      }
      return false
    })
  }

  const onSend = async () => {
    setIsFetchingSimulation(true)
    const response = await services.simulation.sendSimulationByEmail()
    if (response.status === 200) {
      const nextStep = "feedback-proposal"
      next(nextStep)
    }
  }

  return {
    onSubmit,
    onSend,
    getSelectedOption,
    requestNewSimulation,
    formProps,
    stepData,
    isLoading,
    isFetchingSimulation,
    isSimulationChangesBlocked,
    isClosedFlow,
  }
}

const onReceiveSimulation = ({
  data = {},
  formProps,
  setStepData,
  stepData,
  updateCurrentSimulation = false,
}) => {
  const newStepData = updateCurrentSimulation ? data?.simulationOptions : data
  const installments = newStepData?.availableInstallmentOptions
    ? toInstallmentsList(newStepData?.availableInstallmentOptions)
    : []

  const hasSelectedParcel = !!newStepData.availableInstallmentOptions.find(
    (element) => element.selected
  )

  const initialInstallment = installments.find((item) => {
    return hasSelectedParcel ? item.selected : item.suggested
  })

  fillField({
    fieldName: "downPayment",
    value: newStepData?.defaultDownPaymentRaw,
    formProps,
  })
  fillField({
    fieldName: "numberOfInstallments",
    value: initialInstallment?.value,
    formProps,
  })
  fillField({
    fieldName: "installments",
    value: initialInstallment?.label,
    formProps,
  })
  fillField({
    fieldName: "financeProtectionInsurance",
    value: newStepData?.financeProtectionInsurance || false,
    formProps,
  })
  setStepData({
    ...stepData,
    ...newStepData,
  })
  return data
}

export default useSimulation
