import { useForm } from "react-hook-form"

// Utils
import { formatters } from "@garantidos/utils"

// Components
import { Button, Text } from "@mobi/ds"

import { suggestedCities } from "./dataContent"
import { SheetSelect } from "components/SheetSelect"
// Services
import services from "services"

export const cityFilterClearValues = {
	city: "",
	state: "",
	kmRange: "50"
}

export function CityContent({ getValues, onSubmitFilter, onClose, total = 0 }) {
	function getDefaultValues() {
		const city = getValues("city")
		const state = getValues("state")

		if (!city || !state) return { city: null }

		return { city: { label: `${city}, ${state}`, value: city, state } }
	}

	const formProps = useForm({
		defaultValues: getDefaultValues()
	})
	const { watch, handleSubmit, setValue } = formProps

	const currentValue = watch("city")
	const hasValue = !!currentValue?.value

	const fetchOptions = async (search) => {
		if (!search) {
			return [
				...(currentValue
					? [
							{
								group: "você está buscando em",
								label: currentValue.label,
								description:
									total && total > 0
										? `${formatters.numberToStringWithDots(total)} oferta${
												total > 1 ? "s" : ""
										  }`
										: "nenhuma oferta",
								value: currentValue.value,
								state: currentValue.state
							}
					  ]
					: []),
				...suggestedCities.filter(
					(suggestedCity) => suggestedCity.value !== currentValue?.value
				)
			]
		}

		const response = await services.places.getCities({ city: search })
		return response || []
	}

	const onClear = () => {
		onSubmitFilter(cityFilterClearValues)
		onClose()
	}

	const onSubmit = handleSubmit(({ city: { value, state } }) => {
		onSubmitFilter({ city: value, state })
		onClose()
	})

	const onSelectItem = (item) => {
		setValue("city", item)
	}

	return (
		<>
			<div className="filters-drawer__content">
				<Text size="lg" weight="400" className="filters-drawer__title">
					localização
				</Text>
				<SheetSelect.Content
					fetchOptions={fetchOptions}
					currentValue={currentValue?.value}
					onSelectItem={onSelectItem}
					placeholder="digite sua cidade ou estado..."
					getIsChecked={({ value, state }) =>
						value === currentValue?.value && state === currentValue?.state
					}
				/>
			</div>
			<footer className="filters-drawer__footer">
				<Button variant="secondary" onClick={onClear} disabled={!hasValue}>
					limpar
				</Button>
				<Button onClick={onSubmit} disabled={!hasValue}>
					filtrar
				</Button>
			</footer>
		</>
	)
}
