export const sortOptions = [
  {
    value: "featured",
    label: "destaques",
  },
  {
    value: "ascendingPrice",
    label: "menor preço",
  },
  {
    value: "ascendingKm",
    label: "menor km",
  },
  {
    value: "year",
    label: "ano mais novo",
  },
  {
    value: "descendingKm",
    label: "maior km",
  },
  {
    value: "descendingPrice",
    label: "maior preço",
  },
]
