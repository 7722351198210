import { useContext, useEffect } from "react"

//Utils
import { getFromSessionStorage } from "utils/storage"

// Contexts
import { Context } from "contexts/simulation"

// Tracking
import analytics from "@mobi/libraries/analytics"

const useFeedbackProposal = () => {
  const { simulation } = useContext(Context)

  const isClosedFlow = getFromSessionStorage({ name: "isClosedFlow" }) || false
  const sellerStreetName =
    getFromSessionStorage({ name: "sellerStreetName" }) || ""

  useEffect(() => {
    analytics.track("pageLoad", {})
    analytics.track("simulationSent", {})
  }, [])

  return {
    simulation,
    isClosedFlow,
    sellerStreetName,
  }
}

export default useFeedbackProposal
