import { useForm } from "react-hook-form"
import { useState } from "react"

// Components
import { Button, Text, Input } from "@mobi/ds"

// Styles
import "./index.scss"

const PRICES_MIN_VALUE = 5000

export function PricesContent({
  getValues: propsGetValues,
  onSubmitFilter,
  onClose,
}) {
  const [shouldResetOn, setShouldResetOn] = useState(0)

  function getDefaultValues() {
    const initialPrice = propsGetValues("initialPrice") || 0
    const finalPrice = propsGetValues("finalPrice") || 0

    return {
      initialPrice,
      finalPrice,
    }
  }

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: getDefaultValues(),
  })

  const [watchInitialPrice, watchFinalPrice] = watch([
    "initialPrice",
    "finalPrice",
  ])

  const onSubmit = handleSubmit(({ initialPrice, finalPrice }) => {
    onSubmitFilter({
      initialPrice: initialPrice || "",
      finalPrice: finalPrice || "",
    })
    onClose()
  })

  return (
    <>
      <div className="filters-drawer__content">
        <Text size="lg" weight="400" className="filters-drawer__title">
          preço
        </Text>
        <div className="filters-drawer__prices">
          <Input.Currency
            name="initialPrice"
            label="de"
            description="mínimo de R$ 20.000,00"
            autoComplete="on"
            required={false}
            shouldResetOn={shouldResetOn}
            control={control}
            rules={{
              min: PRICES_MIN_VALUE,
              max: 1200000,
            }}
            validators={{
              rules: {
                validate: {
                  min: (value) => !value || value >= PRICES_MIN_VALUE,
                },
              },
            }}
            defaultValue={watchInitialPrice}
            onBlur={(initialPrice) => {
              const finalPrice = getValues("finalPrice")

              if (initialPrice && finalPrice && initialPrice > finalPrice) {
                setValue("initialPrice", finalPrice, {
                  shouldValidate: true,
                })
                setShouldResetOn((old) => old + 1)
              }
            }}
          />
          <Input.Currency
            name="finalPrice"
            label="até"
            description="máximo de R$ 400.000,00"
            autoComplete="on"
            required={true}
            shouldResetOn={shouldResetOn}
            control={control}
            rules={{
              min: PRICES_MIN_VALUE,
              max: 1200000,
            }}
            defaultValue={watchFinalPrice}
            onBlur={(finalPrice) => {
              const initialPrice = getValues("initialPrice")

              if (initialPrice && finalPrice && initialPrice > finalPrice) {
                setValue("finalPrice", initialPrice, {
                  shouldValidate: true,
                })
              } else if (!finalPrice) {
                setValue("finalPrice", "", {
                  shouldValidate: true,
                })
              }

              setShouldResetOn((old) => old + 1)
            }}
          />
        </div>
      </div>
      <footer className="filters-drawer__footer">
        <Button onClick={onSubmit} disabled={!isValid}>
          filtrar
        </Button>
      </footer>
    </>
  )
}
