import * as ReactDOM from "react-dom"

import { VehiclesIcon } from "@garantidos/components"

//Components
import { Accordion, Button, Text } from "@mobi/ds"
import isMobile from "@mobi/utils/browser/isMobile"

import {
	financeInsuranceAccordionItems,
	financeInsuranceConditions
} from "./data"
// Styles
import "./index.scss"
import Modal from "components/Modal"

const FinanceInsuranceModal = ({ isOpened, onCloseClick }) => {
	return ReactDOM.createPortal(
		<Modal
			onCloseClick={onCloseClick}
			isOpened={isOpened}
			variant={isMobile ? "drawer" : "center"}
			className="insurance-modal"
		>
			<div className="insurance-modal__container">
				<Text size="md" className="insurance-modal__title">
					crédito protegido
				</Text>

				<div className="insurance-modal__content">
					{financeInsuranceConditions.map(({ title, description }) => (
						<div key={title} className="insurance-modal__condition">
							<VehiclesIcon
								id="icon-check"
								className="insurance-modal__condition-icon"
							/>
							<div className="insurance-modal__condition-texts">
								<Text size="md" className="insurance-modal__condition-title">
									{title}
								</Text>
								<Text
									size="sm"
									className="insurance-modal__condition-description"
								>
									{description}
								</Text>
							</div>
						</div>
					))}

					{isOpened && (
						<Accordion
							oneAtATime={true}
							items={financeInsuranceAccordionItems}
						/>
					)}
				</div>

				<footer className="insurance-modal__footer">
					<Button
						type="button"
						onClick={onCloseClick}
						data-modal
						variant={"secondary"}
						fluid={isMobile}
					>
						fechar
					</Button>
				</footer>
			</div>
		</Modal>,
		document.body
	)
}

export default FinanceInsuranceModal
