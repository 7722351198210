export const suggestedCities = [
  {
    label: "São Paulo, SP",
    value: "São Paulo",
    state: "SP",
    group: "cidades sugeridas",
  },
  {
    label: "Brasília, DF",
    value: "Brasília",
    state: "DF",
    group: "cidades sugeridas",
  },
  {
    label: "Rio de Janeiro, RJ",
    value: "Rio de Janeiro",
    state: "RJ",
    group: "cidades sugeridas",
  },
  {
    label: "Goiânia, GO",
    value: "Goiânia",
    state: "GO",
    group: "cidades sugeridas",
  },
  {
    label: "Belo Horizonte, MG",
    value: "Belo Horizonte",
    state: "MG",
    group: "cidades sugeridas",
  },
  {
    label: "Porto Alegre, RS",
    value: "Porto Alegre",
    state: "RS",
    group: "cidades sugeridas",
  },
  {
    label: "Cuiabá, MT",
    value: "Cuiabá",
    state: "MT",
    group: "cidades sugeridas",
  },
  {
    label: "João Pessoa, PB",
    value: "João Pessoa",
    state: "PB",
    group: "cidades sugeridas",
  },
  {
    label: "Palmas, TO",
    value: "Palmas",
    state: "TO",
    group: "cidades sugeridas",
  },
  {
    label: "Recife, PE",
    value: "Recife",
    state: "PE",
    group: "cidades sugeridas",
  },
  {
    label: "Belém, PA",
    value: "Belém",
    state: "PA",
    group: "cidades sugeridas",
  },
]
