import { useState } from "react"

// Components
import Layout from "components/Layout"
import Stepper from "./stepper"

//Hooks
import useSimulationLayout from "hooks/useSimulationLayout"

//Utils
import getPageInfo from "utils/getPageInfo"
import { getUUIDFromUrl } from "utils/browser"
import isClient from "@mobi/utils/browser/isClient"

//Style
import "./index.scss"
import { navigateWithQueryString } from "../../utils/browser"

const Form = () => {
  const { currentFormStep } = useSimulationLayout()
  const { pageTitle } = getPageInfo(currentFormStep)
  const [titleOfPageLoaded, setTitleOfPageLoaded] = useState("")

  const uuid = getUUIDFromUrl()

  if (!uuid && isClient()) {
    navigateWithQueryString("/")
    return null
  }

  setTimeout(() => {
    setTitleOfPageLoaded(`${pageTitle} carregado`)
  }, 100)

  return (
    <Layout className={`-form -${currentFormStep}`}>
      <Stepper current={currentFormStep} />
      <p
        className="live-region"
        role="status"
        aria-atomic="true"
        aria-live="assertive"
      >
        {titleOfPageLoaded}
      </p>
    </Layout>
  )
}

export default Form
