import { createContext, useState } from "react"

import { ENVIRONMENT } from "gatsby-env-variables"

import { proposalHttpController } from "../../services"
import { hashDocument } from "../../services/httpRequests"
import { renewTime, setCpfInStorage, setItem } from "../../utils"
import {
	apiSlugInfo,
	getFallbackSlugInfo,
	getSlugInfo
} from "./entities/slugInfo"

const ProposalContext = createContext()

const DEFAULT_ERROR_MESSAGE =
	"Encontramos um erro, por favor tente novamente mais tarde"

const proposalContextApi = ({
	proposalData,
	slugInfo,
	setProposalData,
	setSlugInfo,
	setIsLoading,
	slugsData,
	setErrorMessage,
	setErrorFields,
	cpfData,
	setCpfData
}) => {
	const fetchProposalData = async ({
		hasLoading = true,
		flow = "",
		slug = "",
		recaptcha = false
	}) => {
		setIsLoading(hasLoading)
		clearErrors()
		await checkHashedCpf()
		const { slugs } = slugsData
		const apiStep = slug ? slugs[slug].name : null

		const apiSlug = apiSlugInfo(slugInfo, slugsData)

		flow = flow || apiSlug.flow
		const step = apiStep || apiSlug.current_step

		try {
			const response = await proposalHttpController({
				method: "get",
				flow,
				step,
				recaptcha
			})

			updateProposalData({ payload: response.step_data, clear: true })
			updateSlugInfo(response.step_navigation)
			return response.step_data
		} finally {
			setIsLoading(false)
		}
	}

	const sendProposalData = async ({
		method = "post",
		payload,
		slug = "",
		flow = "",
		hasLoading = true,
		clearStepNaviagation = true
	}) => {
		setIsLoading(hasLoading)
		clearErrors()
		const cpf =
			payload.cpf ?? payload.proponent_document ?? payload.document_number
		await checkHashedCpf(cpf)

		const { flow: currentFlow, current_step } = apiSlugInfo(slugInfo, slugsData)

		flow = flow ? flow : currentFlow

		try {
			const response = await proposalHttpController({
				payload,
				method,
				flow,
				step: slug || current_step
			})

			updateProposalData({ payload: response.step_data, clear: true })
			updateSlugInfo(response.step_navigation)

			const fullStepResponse = {
				stepData: response.step_data,
				stepNavigation: getSlugInfo(response.step_navigation, slugsData)
			}
			return clearStepNaviagation ? response.step_data : fullStepResponse
		} catch (err) {
			throw err
		} finally {
			setIsLoading(false)
		}
	}

	const checkFinanceExpense = async ({ hasLoading = true, recaptcha }) => {
		setIsLoading(hasLoading)
		try {
			const response = await proposalHttpController({
				method: "financeExpense",
				recaptcha
			})
			return response.message || ""
		} finally {
			setIsLoading(false)
		}
	}

	const fetchAsset = async ({ hasLoading = true, payload, recaptcha }) => {
		setIsLoading(hasLoading)
		try {
			const response = await proposalHttpController({
				method: "calculateAssetValue",
				payload: payload,
				recaptcha
			})

			return response
		} catch (err) {
			throw err
		} finally {
			setIsLoading(false)
		}
	}

	const fetchDeadlines = async ({ hasLoading = true, payload, recaptcha }) => {
		setIsLoading(hasLoading)
		try {
			const response = await proposalHttpController({
				method: "calculateDeadlines",
				payload,
				recaptcha
			})

			return response
		} finally {
			setIsLoading(false)
		}
	}

	const checkFinanceIOF = async ({ hasLoading = true }) => {
		setIsLoading(hasLoading)
		try {
			const response = await proposalHttpController({
				method: "financeIof"
			})
			return response.message || ""
		} finally {
			setIsLoading(false)
		}
	}

	const resendToken = async ({ hasLoading = true }) => {
		setIsLoading(hasLoading)
		try {
			const response = await proposalHttpController({
				method: "resendToken"
			})
			return response.message || ""
		} finally {
			setIsLoading(false)
		}
	}

	const updateProposalData = async ({ payload = {}, clear = false }) => {
		if (clear) {
			setProposalData(payload)
		} else {
			setProposalData((prevData) => ({
				...prevData,
				...payload
			}))
		}
	}

	const updateSlugInfo = (data) => {
		const newSlugInfo = getSlugInfo(data, slugsData)

		const fallBackSlugInfo = getFallbackSlugInfo(newSlugInfo, slugsData)
		const sanitizedSlugInfo = {
			currentFlow: newSlugInfo.currentFlow || fallBackSlugInfo.currentFlow,
			currentSlug: newSlugInfo.currentSlug || fallBackSlugInfo.currentSlug,
			previousSlug: newSlugInfo.previousSlug || fallBackSlugInfo.previousSlug,
			nextSlug: newSlugInfo.nextSlug || fallBackSlugInfo.nextSlug
		}

		setSlugInfo(sanitizedSlugInfo)
	}

	const errorHandler = (error) => {
		if (error === null || error === undefined) {
			error = { message: DEFAULT_ERROR_MESSAGE }
		}
		setErrorMessage(error.message)
		if (error.form_validation_error?.fields) {
			setErrorFields(error.form_validation_error.fields)
		}
	}

	const clearErrors = (errorMessage = true, errorFields = true) => {
		errorMessage && setErrorMessage("")
		errorFields && setErrorFields({})
	}

	const checkHashedCpf = async (newCpf = null) => {
		const { cpf, hashedCpf } = cpfData

		if (newCpf === cpf && !!hashedCpf) return
		if (!newCpf && !!cpf && !!hashedCpf) return

		const newCpfData = { cpf: newCpf ?? cpf ?? "", hashedCpf: hashedCpf ?? "" }

		if (newCpf !== cpf || !hashedCpf) {
			const newHashedCpf = await hashDocument(newCpfData.cpf)
			newCpfData.hashedCpf = newHashedCpf
		}

		setCpfData(newCpfData)
		setCpfInStorage(newCpfData)
	}

	const updateCpfData = ({ cpf = "", hashedCpf = "" }) => {
		setCpfData({ cpf, hashedCpf })
	}

	const retrieveProposal = async ({ route, recaptcha }) => {
		const responseToken = await proposalHttpController({
			method: "retrieveProposal",
			route,
			recaptcha
		})
		const { step_data, step_navigation } = responseToken

		await updateSlugInfo(step_navigation)
		await updateProposalData({ payload: step_data, clear: true })
	}

	const retrieveProposalRemarketing = async ({
		route,
		recaptcha,
		remarketingId
	}) => {
		const response = await proposalHttpController({
			method: "retrieveProposalRemarketing",
			route,
			recaptcha,
			payload: { remarketingId }
		})

		if (!response || typeof response !== "object") return

		const { step_data, step_navigation, refresh_token } = response

		if (step_data) await updateProposalData({ payload: step_data, clear: true })
		if (step_navigation) await updateSlugInfo(step_navigation)
		if (refresh_token) {
			const { token, valid_time, server_time } = refresh_token
			const expires = renewTime(valid_time, server_time)
			const authData = { token, expires }
			setItem("appToken", JSON.stringify(authData))
		}
	}

	return {
		fetchProposalData,
		sendProposalData,
		checkFinanceExpense,
		checkFinanceIOF,
		resendToken,
		fetchDeadlines,
		fetchAsset,
		updateProposalData,
		updateSlugInfo,
		errorHandler,
		checkHashedCpf,
		updateCpfData,
		retrieveProposal,
		retrieveProposalRemarketing
	}
}

const getProposalContext = ({ slugsData }) => {
	const ProposalDataProvider = ({ children, initialSlugData = {} }) => {
		const initialSlugInfo = {
			currentFlow: initialSlugData.currentFlow ?? "default",
			currentSlug: initialSlugData.currentSlug ?? "softlead",
			previousSlug: initialSlugData.previousSlug ?? "softlead",
			nextSlug: initialSlugData.nextSlug ?? "simulation"
		}

		const [proposalData, setProposalData] = useState({})
		const [cpfData, setCpfData] = useState({ cpf: "", hashedCpf: "" })
		const [slugInfo, setSlugInfo] = useState(initialSlugInfo)
		const [isLoading, setIsLoading] = useState(false)
		const [isLoaderComponentVisible, setIsLoaderComponentVisible] =
			useState(false)
		const [isStepVisible, setIsStepVisible] = useState(true)
		const [errorMessage, setErrorMessage] = useState("")
		const [errorFields, setErrorFields] = useState({})

		if (ENVIRONMENT !== "production") {
			console.log(
				"%c ============ [Current Context] ============",
				"color: green"
			)
			console.log({ slugInfo, proposalData, cpfData })
		}

		const {
			fetchProposalData,
			sendProposalData,
			checkFinanceExpense,
			checkFinanceIOF,
			fetchDeadlines,
			fetchAsset,
			resendToken,
			updateProposalData,
			updateSlugInfo,
			errorHandler,
			checkHashedCpf,
			updateCpfData,
			retrieveProposal,
			retrieveProposalRemarketing
		} = proposalContextApi({
			proposalData,
			setProposalData,
			slugInfo,
			setSlugInfo,
			setIsLoading,
			slugsData,
			setErrorMessage,
			setErrorFields,
			cpfData,
			setCpfData
		})

		return (
			<ProposalContext.Provider
				value={{
					slugInfo,
					proposalData,
					fetchProposalData,
					sendProposalData,
					checkFinanceExpense,
					checkFinanceIOF,
					resendToken,
					fetchDeadlines,
					fetchAsset,
					updateProposalData,
					updateSlugInfo,
					isLoading,
					isLoaderComponentVisible,
					setIsLoaderComponentVisible,
					setIsLoading,
					errorMessage,
					setErrorMessage,
					errorFields,
					setErrorFields,
					errorHandler,
					cpfData,
					checkHashedCpf,
					updateCpfData,
					retrieveProposal,
					retrieveProposalRemarketing,
					isStepVisible,
					setIsStepVisible
				}}
			>
				{children}
			</ProposalContext.Provider>
		)
	}

	return { ProposalContext, ProposalDataProvider }
}

export default getProposalContext
