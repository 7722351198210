//Components
import { Text, Button } from "@mobi/ds"
import If from "@mobi/components/If"
import Modal from "components/Modal"
import isMobile from "@mobi/utils/browser/isMobile"

// Styles
import "./index.scss"

const ModalCET = ({
  isOpened,
  onCloseClick,
  vehicleDealProperties,
  totalAmount,
  totalPayable,
}) => {
  return (
    <Modal
      onCloseClick={onCloseClick}
      isOpened={isOpened}
      variant={isMobile ? "drawer" : "center"}
    >
      <Text size="md" className="cet-modal__title">
        ver custo efetivo total CET
      </Text>
      <ul className="modal__list">
        <If
          condition={vehicleDealProperties?.registrationFee}
          renderIf={
            <li>
              <Text size="md">tarifa de cadastro</Text>
              <Text size="md">R$ {vehicleDealProperties?.registrationFee}</Text>
            </li>
          }
        />
        <If
          condition={vehicleDealProperties?.propertyValuationFee}
          renderIf={
            <li>
              <Text size="md">tarifa de avaliação de bens</Text>
              <Text size="md">
                R$ {vehicleDealProperties?.propertyValuationFee}{" "}
              </Text>
            </li>
          }
        />
        <If
          condition={vehicleDealProperties?.contractRegistrationFee}
          renderIf={
            <li>
              <Text size="md">registro de contrato</Text>
              <Text size="md">
                R$ {vehicleDealProperties?.contractRegistrationFee}
              </Text>
            </li>
          }
        />
        <If
          condition={vehicleDealProperties?.iofValue}
          renderIf={
            <li>
              <Text size="md">IOF</Text>
              <Text size="md">R$ {vehicleDealProperties?.iofValue}</Text>
            </li>
          }
        />
        <If
          condition={
            vehicleDealProperties?.monthlyInterest &&
            vehicleDealProperties?.annualInterest
          }
          renderIf={
            <>
              <li>
                <Text size="md">taxa de juros ao mês:</Text>
                <Text size="md">{vehicleDealProperties?.monthlyInterest}%</Text>
              </li>
              <li>
                <Text size="md">taxa de juros ao ano: </Text>
                <Text size="md">{vehicleDealProperties?.annualInterest}%</Text>
              </li>
            </>
          }
        />
        <If
          condition={
            vehicleDealProperties?.monthlyEffectiveInterest &&
            vehicleDealProperties?.annualEffectiveInterest
          }
          renderIf={
            <>
              <li>
                <Text size="md">CET anual</Text>
                <Text size="md">
                  {vehicleDealProperties?.annualEffectiveInterest}%
                </Text>
              </li>
              <li>
                <Text size="md">CET mensal</Text>
                <Text size="md">
                  {vehicleDealProperties?.monthlyEffectiveInterest}%
                </Text>
              </li>
            </>
          }
        />
        <If
          condition={vehicleDealProperties?.totalAmountFinanced}
          renderIf={
            <li>
              <Text size="md">valor total financiado</Text>
              <Text size="md">R$ {totalAmount}</Text>
            </li>
          }
        />
        <If
          condition={totalPayable}
          renderIf={
            <li>
              <Text size="md" weight={800}>
                Total a pagar:{" "}
              </Text>
              <Text size="md" weight={800}>
                R$ {totalPayable}
              </Text>
            </li>
          }
        />
      </ul>
      <Button
        type="button"
        onClick={onCloseClick}
        data-modal
        variant={"secondary"}
        fluid={isMobile}
      >
        fechar
      </Button>
    </Modal>
  )
}

export default ModalCET
