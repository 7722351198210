import { useState } from "react"
import "react-input-range/lib/css/index.css"

import { VehiclesIcon } from "@garantidos/components"

import If from "@mobi/components/If"
//Components
import { Button, Text } from "@mobi/ds"
// Utils
import isMobile from "@mobi/utils/browser/isMobile"

//Style
import "./index.scss"
// Hooks
import useDeal from "./useDeal"
import classnames from "classnames"
import { BackButton } from "components/BackButton"
import DealCard from "components/DealCard"
import Header from "components/Header"
import Loader from "components/Loader"
import Modal from "components/Modal"
import Pagination from "components/Pagination"
import Stepper from "components/Stepper"
import VehicleDetails from "components/VehicleDetails"
import EmptyIcon from "images/empty.svg"

import { DesktopFiltersButtons } from "./components/DesktopFiltersButtons"
import { FiltersModal } from "./components/FiltersModal"
import { MobileFiltersButtons } from "./components/MobileFiltersButtons"
import { QuantityFound } from "./components/QuantityFound"

const Deal = ({ current }) => {
	const [isVehicleDetailsOpen, setIsVehicleDetailsOpen] = useState(false)
	const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false)
	const [openedFilter, setOpenedFilter] = useState("")

	const { title } = current

	const {
		isStepLoading,
		isDealsLoading,
		stepData,
		selectedVehicle,
		currentPage,
		nextFormStep,
		onNext,
		onPrev,
		selectVehicle,
		searchDeals,
		onSubmitFilter,
		filtersFormProps: { getValues, watch },
		isSubmitting
	} = useDeal()

	const { deals, pages, total } = stepData

	const isEmpty = !(deals && deals.length > 0)

	const onClickPagination = (newPage) => {
		searchDeals({ page: newPage }, newPage)
		window.scrollTo(0, 0)
	}

	return (
		<>
			<If condition={!isMobile} renderIf={<Header />} />
			<If
				condition={isStepLoading}
				renderIf={<Loader variant="spinner-only" />}
			/>
			<If
				condition={isSubmitting}
				renderIf={
					nextFormStep === "verification-code-generation" ? (
						<Loader variant="spinner-only" />
					) : (
						<Loader />
					)
				}
			/>
			<div
				className={classnames("step step-deal", {
					"step-deal--blocked":
						isVehicleDetailsOpen || (isMobile && openedFilter)
				})}
			>
				<If
					condition={!isMobile}
					renderIf={
						<DesktopFiltersButtons
							setOpenedFilter={setOpenedFilter}
							openedFilter={openedFilter}
						/>
					}
					renderElse={
						<MobileFiltersButtons
							isOpen={isFiltersModalOpen}
							onClose={() => setIsFiltersModalOpen(false)}
							onOpenFilter={(newOpenedFilter) => {
								setIsFiltersModalOpen(false)
								setOpenedFilter(newOpenedFilter)
							}}
							getValues={getValues}
							onSubmitFilter={onSubmitFilter}
						/>
					}
				/>

				<div
					className={classnames("step-deal__content", {
						"step-deal__content--filters-open": !!openedFilter && !isMobile
					})}
				>
					{!isMobile && (
						<span
							className={classnames("step-deal__filters-drawer-ghost", {
								"step-deal__filters-drawer-ghost--opened": openedFilter
							})}
						/>
					)}

					<FiltersModal
						openedFilter={openedFilter}
						setOpenedFilter={setOpenedFilter}
						onSubmitFilter={onSubmitFilter}
						getValues={getValues}
						watch={watch}
						onClose={() => setOpenedFilter("")}
						total={total}
					/>

					<div
						className={classnames("step-deal__container", {
							"step-deal__container--filters-open": !!openedFilter && !isMobile
						})}
					>
						<div className="step-deal__headline">
							<BackButton text="voltar" onClick={onPrev} />
							<Stepper index={2} max={3} />
							<Text className="step__title" as="h1" size="xxl">
								<strong>{title}</strong>
							</Text>
							<Text size="md">
								qual o carro você quer simular um financiamento?
							</Text>
						</div>
						<div className="step-deal__board">
							<div className="step-deal__board-headline">
								<If
									condition={!isEmpty}
									renderIf={<QuantityFound total={total} watch={watch} />}
								/>
								<If
									condition={isMobile}
									renderIf={
										<div className="step-deal__board-headline-actions">
											<Button
												variant="secondary"
												fluid
												rightIcon={<VehiclesIcon id="icon-sort" />}
												className="step-deal__filter-button"
												onClick={() =>
													setOpenedFilter((old) =>
														old === "sort" ? "" : "sort"
													)
												}
											>
												ordernar
											</Button>
											<Button
												variant="secondary"
												fluid
												rightIcon={<VehiclesIcon id="icon-filter" />}
												className="step-deal__filter-button"
												onClick={() => setIsFiltersModalOpen(true)}
											>
												filtrar
											</Button>
										</div>
									}
								/>
							</div>
							<div
								className={classnames("step-deal__board-grid", {
									"step-deal__board-grid--empty": isEmpty && !isDealsLoading
								})}
							>
								<If
									condition={isDealsLoading}
									renderIf={
										<>
											<div className="deal-card--is-loading"></div>
											<div className="deal-card--is-loading"></div>
											<div className="deal-card--is-loading"></div>
											<div className="deal-card--is-loading"></div>
											<div className="deal-card--is-loading"></div>
											<div className="deal-card--is-loading"></div>
											<div className="deal-card--is-loading"></div>
											<div className="deal-card--is-loading"></div>
											<div className="deal-card--is-loading"></div>
										</>
									}
									renderElse={
										<If
											condition={!isEmpty}
											renderIf={
												<>
													{deals.map((item) => {
														return (
															<DealCard
																key={item.dealID}
																item={item}
																selectedVehicle={selectedVehicle}
																onNext={onNext}
																selectVehicle={selectVehicle}
																setIsModalOpened={setIsVehicleDetailsOpen}
															/>
														)
													})}

													<Modal
														isOpened={isVehicleDetailsOpen}
														onCloseClick={() => setIsVehicleDetailsOpen(false)}
														variant={isMobile ? "drawer" : "center"}
													>
														<VehicleDetails
															selectedVehicle={selectedVehicle}
															selectVehicle={selectVehicle}
															onClose={() => setIsVehicleDetailsOpen(false)}
															onNext={onNext}
														/>
													</Modal>
												</>
											}
											renderElse={
												<div className="step-deal__grid-empty">
													<EmptyIcon />
													<Text
														as="p"
														size="xxl"
														weight={700}
														className="step-deal__grid-empty-title"
													>
														desculpe, não encontramos nada
													</Text>
													<Text as="p" size="md" weight={400}>
														Tente novamente editando os dados do veículo para
														melhorar a sua busca.
													</Text>
												</div>
											}
										/>
									}
								/>
							</div>
							<If
								condition={!isEmpty}
								renderIf={
									<Pagination
										pages={pages}
										currentPage={currentPage - 1}
										onSelectPage={({ target }) => {
											const newPage = target?.value
												? parseInt(target?.value) + 1
												: 1
											onClickPagination(newPage)
										}}
										onPrev={() => {
											if (currentPage > 0) {
												const newPage = currentPage - 1
												onClickPagination(newPage)
											}
										}}
										onNext={() => {
											if (currentPage < pages) {
												const newPage = currentPage + 1
												onClickPagination(newPage)
											}
										}}
									/>
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Deal
