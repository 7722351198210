import { useEffect } from "react"

//import { FocusOn } from "react-focus-on"
// Components
import { VehiclesIcon } from "@garantidos/components"

// Utils
import isClient from "@mobi/utils/browser/isClient"

// Styles
import "./index.scss"
import classnames from "classnames"

const Modal = ({
	children,
	isOpened,
	onCloseClick,
	variant = "center",
	hasCloseButton = true,
	className
}) => {
	useEffect(() => {
		if (isClient() && isOpened) {
			const btnClose = document.querySelector(".modal__close")
			btnClose && btnClose.focus()
		}
	}, [isOpened])

	return (
		<div
			className={classnames("modal", className, {
				["modal--opened"]: isOpened,
				[`modal--${variant}`]: !!variant
			})}
			role="alertdialog"
			aria-modal="true"
		>
			<div
				className={`modal__curtain ${isOpened ? "modal__curtain--opened" : ""}`}
				onClick={onCloseClick}
				aria-label="Fechar modal"
			></div>
			<div
				className={classnames("modal__box", {
					["modal__box--opened"]: isOpened,
					[`modal__box--${variant}`]: !!variant
				})}
			>
				{hasCloseButton && (
					<button
						aria-label="Fechar o modal"
						className="modal__close"
						onClick={onCloseClick}
					>
						<VehiclesIcon className="modal__close-icon" id="icon-close" />
					</button>
				)}
				<div className="modal__content">{children}</div>
			</div>
		</div>
	)
}

export default Modal
