import { useController } from "react-hook-form"

export function useSwitch({ name, control, rules, onChange }) {
  const {
    field: { value: isChecked, ...inputProps },
  } = useController({
    name,
    control,
    rules,
  })

  const handleOnClick = () => {
    inputProps.onChange(!isChecked)
    onChange && onChange()
  }

  return {
    isChecked,
    handleOnClick,
    inputProps,
  }
}
